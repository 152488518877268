import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { Cart } from 'sparrowhub-client-axios';
import { formatCartLink } from '../../helpers/utils';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { CopyText } from '../CopyText/CopyText';
import { CartBadges } from '../CartBadges/CartBadges';
import { OrderDetails } from '../OrderDetails/OrderDetails';

import closeIcon from '../../assets/images/icons/Close.svg';


type CopyCartLinkModalProps = {
  show: boolean
  cart: Cart
  onClose: Function
}

export const CopyCartLinkModal: FunctionComponent<CopyCartLinkModalProps> = ({ show, cart, onClose }) => {
  return (
    <StyledCopyCartLinkModal className="CopyCartLinkModal">
      <Modal show={show}>
        <Heading heading="Copy cart link" />
        <div className="OrderNotes_details divider">
          <CartBadges cart={cart} />
          <OrderDetails order={cart} phone={cart.delivery_phone !== null} email={cart.delivery_email !== null} cart bold={false} />
        </div>
        
        <p className="bold">Copy the the link below to send a trackable cart to your patients.</p>
        <CopyText text={formatCartLink(cart.reference)} />

        <img src={closeIcon} alt="Close" className="Close button top_right" onClick={() => onClose()} draggable="false" />
      </Modal>
    </StyledCopyCartLinkModal>
  );
}

const StyledCopyCartLinkModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;

    .CopyCartLinkModal_logo {
      height: 47px;
      width: auto;
      margin-bottom: 20px;
    }

    .Heading {
      margin-bottom: 26px;
    }

    form {
      margin-top: 40px;

      .divider {
        padding-bottom: 10px;
        margin-bottom: 34px;
      }

      .InputField {
        margin: 24px 0;
      }

      select, input {
        font-size: 0.875rem !important; // 14px
      }

      .CopyCartLinkModal_columns {
        display: grid;
        gap: 10px;
        grid-gap: 10px;
        grid-template-columns: 275px 1fr;
        margin-bottom: -15px;

        & > div {
          margin: auto 0 24px 0;

          input {
            margin-bottom: 0;
          }
        }
      }
    }

    p.bold {
      margin-bottom: 30px;
    }

    .Button_primary {
      margin-top: 50px;
      margin-bottom: 23px;
    }

    .top_right {
      top: 35px;
      right: 35px;
    }
  }
`