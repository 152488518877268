import { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';

import { IOrder, OrderDeliveryType } from '../../types/IOrders';
import { IOrderNote, IOrderNotes } from '../../types/IOrderNotes';
import { IRequestBodyCreateOrderNote, IRequestBodyUpdateOrderNote } from '../../types/IRequests';
import { formatTimestamp } from '../../helpers/utils';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { SparrowHubApiInterface } from 'sparrowhub-client-axios';
import { useDummyData } from '../../context/DummyDataProvider';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { TextArea } from '../TextArea/TextArea';
import { OrderBadges } from '../OrderBadges/OrderBadges';
import { DefaultErrorMessage } from '../DefaultErrorMessage/DefaultErrorMessage';


type OrderNotesModalProps = {
  show: boolean
  order: IOrder
  currentNoteId: number
  onClose?: Function
  onRefresh: Function
}

const getCurrentNote = (notes: IOrderNotes, id: number): IOrderNote | undefined => {
  return notes.find(note => note.id === id);
}

export const OrderNotesModal: FunctionComponent<OrderNotesModalProps> = ({ show, order, currentNoteId, onClose, onRefresh }) => {
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();
  const dummyData = useDummyData();

  // state
  const [initialValue, setInitialValue] = useState('');
  const [noteInput, setNoteInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  
  // set initial values
  useEffect(() => {
    const currentNote = getCurrentNote(order.notes, currentNoteId);
    const noteString = !currentNote ? '' : currentNote.note;
    setInitialValue(noteString);
    setNoteInput(noteString);
  }, [ currentNoteId ]);

  // methods
  const saveChanges = (): void => {
    setErrorMessage('');
    if (currentNoteId === -1) {
      handleCreateNote();
    } else {
      handleUpdateNote();
    }
  }

  const successDuration = 3000;
  const handleCreateNote = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      if (dummyData.state.useDummyData) {
        // dummyData.mutations.setOrderStatus(order.id, orderStatus);
        resolve('');
      } else {
        const requestBody: IRequestBodyCreateOrderNote = {
          note: noteInput.trim(),
          order_id: order.id,
          partner_id: order.partner_id,
          is_visible: true
        }
        setIsLoading(true);
        apiHelper.createOrderNote(requestBody).then((response) => {
          console.log(response);
          setIsLoading(false);
          if (response.status === 200) {
            onRefresh();
            handleClose();
            // setShowSuccess(true);
            // setTimeout(() => {
            //   handleClose();
            // }, successDuration);
            resolve(response.body.data);
          } else {
            setErrorMessage(`Error creating order note: ${response.body.message}`);
            reject(response.body.message);
          }
        });
      }
    });
  }
  
  const handleUpdateNote = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      if (dummyData.state.useDummyData) {
        // dummyData.mutations.setOrderStatus(order.id, orderStatus);
        resolve('');
      } else {
        const requestBody: IRequestBodyUpdateOrderNote = {
          note: noteInput.trim(),
        }
        setIsLoading(true);
        apiHelper.updateOrderNote(currentNoteId, requestBody).then((response) => {
          console.log(response);
          setIsLoading(false);
          if (response.status === 200) {
            onRefresh();
            handleClose();
            // setShowSuccess(true);
            // setTimeout(() => {
            //   handleClose();
            // }, successDuration);
            resolve(response.body.data);
          } else {
            setErrorMessage(`Error updating order note: ${response.body.message}`);
            reject(response.body.message);
          }
        });
      }
    });
  }

  const validChanges = (): boolean => {
    return noteInput.trim() !== initialValue.trim();
  }

  const transitionDuration = 300;
  const handleClose = (): void => {
    setTimeout(() => {
      // reset state?
      // reset success message
      setShowSuccess(false);
    }, transitionDuration);

    setInitialValue('');
    setNoteInput('');

    if (onClose) onClose();
  }

  return (
    <StyledOrderNotesModal className="OrderNotes">
      <Modal show={show}>
        {!showSuccess ?
          <>
            <Heading heading="Order Notes" />
            <div className="OrderNotes_details divider">
              <OrderBadges order={order} />
              <OrderDetails order={order} phone email bold={false} />
            </div>

            {currentNoteId === -1 &&
              <TextArea id="notes-input" label="New order note" value={noteInput} maxLength={512} small onChange={(e: ChangeEvent) => setNoteInput((e.target as HTMLTextAreaElement).value)} />
            }

            <div className="OrderNotes_existing">
              {order.notes.map((note: IOrderNote) => {
                if (note.id === currentNoteId) {
                    return (
                      <div key={`order-${order.id}-note-${note.id}`}>
                        <TextArea id="notes-input" label="Edit order note" value={noteInput} maxLength={512} small onChange={(e: ChangeEvent) => setNoteInput((e.target as HTMLTextAreaElement).value)} />
                      </div>
                    )
                  } else {
                    return (
                      <div key={`order-${order.id}-note-${note.id}`} className="OrderNotes_note">
                        <p>
                          <span>{formatTimestamp(note.created_at)} - </span>
                          <span>{note.note}</span>
                        </p>
                      </div>
                    )
                  }
              })}
            </div>

            {errorMessage &&
              <div style={{ marginTop: '40px', marginBottom: '-15px' }}>
                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                  <p>{errorMessage}<br/><DefaultErrorMessage /></p>
                </Alert>
              </div>
            }
            <Button type={ButtonType.Primary} text="Save Changes" onClick={saveChanges} disabled={!validChanges()} loading={isLoading} />
            <Button type={ButtonType.Secondary} text="Cancel" onClick={handleClose} />
          </>
        :
          <>
            <Alert type={AlertType.PositiveSecondary} successModal>
              <p>Order notes have successfully been updated</p>
            </Alert>
          </>
        }
      </Modal>
    </StyledOrderNotesModal>
  );
}

const StyledOrderNotesModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;

    /* .Badge {
      position: absolute;
      top: 60px;
      right: 50px;
    } */
    
    .OrderNotes_details {
      margin-top: 28px;
      margin-bottom: 38px;

      .Badge {
        display: inline-flex;
        margin-right: 8px;
      }
    }

    .OrderNotes_existing {
      textarea {
        margin-bottom: 0px;
      }

      .OrderNotes_note {
        padding: 8px;
        margin: 10px 0;
        border-radius: 4px;
        background: #DADADA;
        p {
          margin: 0;
          font-size: 0.875rem; // 14px
          word-wrap: break-word;
        }
      }
    }

    .Button_primary {
      margin-top: 34px;
      margin-bottom: 23px;
    }
  }
`