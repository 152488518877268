import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { boxShadows, transitions } from '../../assets/css/variables';

import arrowIcon from '../../assets/images/icons/BackArrow.svg';

type ProcessStepHeadingProps = {
  heading?: string
  prevLabel?: string
  prevButtonType?: 'primary' | 'secondary' | 'complete'
  prevDisabled?: boolean
  prevLoading?: boolean
  onPrev?: Function
  nextLabel?: string
  nextButtonType?: 'primary' | 'secondary' | 'complete'
  nextDisabled?: boolean
  nextLoading?: boolean
  onNext?: Function
}

export const ProcessStepHeading: FunctionComponent<ProcessStepHeadingProps> = ({ heading, prevLabel, prevButtonType, prevDisabled, prevLoading, onPrev, nextLabel, nextButtonType, nextDisabled, nextLoading, onNext }) => {
  // methods
  const handlePrev = (): void => {
    if (onPrev) onPrev();
  }
  
  const handleNext = (): void => {
    if (onNext) onNext();
  }

  return (
    <StyledProcessStepHeading className="ProcessStepHeading">
      {onPrev &&
        <div className={`ProcessStepHeading_stepButton ${prevButtonType} ${prevDisabled && 'disabled'} ${prevLoading && 'loading'}`} onClick={handlePrev}>
          <img src={arrowIcon} alt="" draggable="false" />
          <p>{prevLabel}</p>
        </div>
      }

      {heading &&
        <h2>{heading}</h2>
      }
      
      {onNext &&
        <div className={`ProcessStepHeading_stepButton ${nextButtonType} ${nextDisabled && 'disabled'} ${nextLoading && 'loading'}`} onClick={handleNext}>
          <p>{nextLabel}</p>
          <img src={arrowIcon} alt="" draggable="false" />
        </div>
      }
    </StyledProcessStepHeading>
  );
}

ProcessStepHeading.defaultProps = {
  prevLabel: 'Back',
  nextLabel: 'Next',
  prevButtonType: 'secondary',
  nextButtonType: 'primary',
}

const StyledProcessStepHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 55px;

  h2 {
    font-size: 1.25rem; // 20px
    margin: 0;
  }

  .ProcessStepHeading_stepButton {
    width: 112px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 6px;
    cursor: pointer;
    
    ${boxShadows.default}
    transition:
      box-shadow ${transitions.default},
      transform ${transitions.default},
      opacity ${transitions.default};

    img {
      width: 12px;
      height: auto;
      margin-top: 2px;
    }
    
    p {
      margin: 0;
      font-size: 0.8rem; // 12px
      font-weight: bold;
    }

    &:hover {
      ${boxShadows.hover}
    }

    &.primary {
      background: black;
      color: white;

      img {
        filter: invert();
        transform: rotate(180deg);
      }
    }
    
    &.complete {
      background: #35C680;
      color: white;

      img {
        filter: invert();
        transform: rotate(180deg);
      }
    }
    
    &.secondary {
      background: white;
      color: black;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    
    &.loading {
      cursor: wait;
      opacity: 0.5;

      &:active {
        pointer-events: none;
      }
    }
  }
`