import { ChangeEvent, FunctionComponent, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colours } from '../../assets/css/variables';
import { useReactToPrint } from "react-to-print";

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { SparrowHubApiInterface, QueueStates } from 'sparrowhub-client-axios';

import { OrderDeliveryType, OrderStatus, OrderUrgency } from '../../types/IOrders';
import { IRequestBodyUpdateOrder } from '../../types/IRequests';
import { IUser } from '../../types/IUsers';
import { IScriptQueueConfig } from '../../types/IScriptQueueConfig';
import { QueueElement, QueueStatuses, ProcessQueueElementUpdateRequest, QueueItem, QueueItemTypes, ProcessNotificationNewRequest, NotificationTypes, Location, UpdateQueueElementRequest } from "sparrowhub-client-axios";
import { getCurrentTimestamp } from '../../helpers/utils';

import { Badge } from '../Badge/Badge';
import { Button, ButtonType } from '../Button/Button';
import { DropdownMenu, DropdownMenuItem } from '../DropdownMenu/DropdownMenu';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { TimeAgo } from '../TimeAgo/TimeAgo';
import { OrderNotes } from '../OrderNotes/OrderNotes';
import { ScriptQueueConfirmationModal } from '../ScriptQueueConfirmationModal/ScriptQueueConfirmationModal';
import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { TextArea } from '../TextArea/TextArea';
import { Accordion } from '../Accordion/Accordion';
import { ScriptDetails } from '../ScriptDetails/ScriptDetails';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { ScriptDocket, ScriptDocketType } from '../ScriptDocket/ScriptDocket';
import { DefaultErrorMessage } from '../DefaultErrorMessage/DefaultErrorMessage';
import { ScriptQueueNotificationModal } from '../ScriptQueueNotificationModal/ScriptQueueNotificationModal';

import tagCheckIcon from '../../assets/images/tags/Check.svg';
import tagCrossIcon from '../../assets/images/tags/Cross.svg';
import tagExclamationIcon from '../../assets/images/tags/Exclamation.svg';
import editBlueIcon from '../../assets/images/icons/Edit_blue.svg';
import phoneIcon from '../../assets/images/icons/Phone.svg';
import personIcon from '../../assets/images/icons/Person.svg';
import { ScriptQueueNotesModal } from '../ScriptQueueNotesModal/ScriptQueueNotesModal';


type ScriptQueueTileProps = {
  order: QueueElement
  location: Location
  highlight?: boolean
  orders: Array<any>
  setOrders: Function
  user: IUser | null
  config: IScriptQueueConfig
  onRefresh: Function
}

export const ScriptQueueTile: FunctionComponent<ScriptQueueTileProps> = ({ order, location, highlight, orders, setOrders, user, config, onRefresh }) => {
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();
  const navigate = useNavigate();

  const customerPrintRef = useRef(null);
  const pharmacistPrintRef = useRef(null);

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showEditDetailsModal, setShowEditDetailsModal] = useState(false);
  const [notesInput, setNotesInput] = useState<Array<string>>(order.queue_items ? order.queue_items.map(item => item.queue_item_note || '') : []);

  const [errorMessage, setErrorMessage] = useState('');

  // methods
  const handleProcessOrder = (): void => {
    switch (order.queue_status_code) {
      case QueueStatuses.New:
      case QueueStatuses.DispensingInProgress:
      case QueueStatuses.Dispensed:
        setShowConfirmationModal(true);
        break;
      case QueueStatuses.AwaitingCollection:
        navigate(`/queue/process/collect-script/${order.id}`);
        break;
    }
  }

  const handleMarkAwaitingCollection = async (): Promise<void> => {
    await updateQueueElementStatus(QueueStatuses.AwaitingCollection);
    await handleSendNotification(order);
  }

  const handleSendNotification = async (queueElement: QueueElement): Promise<any> => {
    await grecaptcha.enterprise.ready(async () => {
      const recaptcha = await grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'contact' });
      sendNotification(queueElement, recaptcha);
    });
  }

  const sendNotification = async (queueElement: QueueElement, recaptcha: string): Promise<any> => {
    // prepare notification text
    let notificationText = `Your order (${queueElement.queue_element_number}) is now ready for collection.\n\nRegards, ${location!.name}`;
    
    if (user?.partner_id === 4) {
      const scriptsUrl = 'bit.ly/escript-delivery'
      notificationText += `\n\n---\n\nDid you know?\n\nWe can deliver your next eScript in under 2 hours!\n\nFind out more:\n\n${scriptsUrl}`;
    }

    // send sms
    const requestBody: ProcessNotificationNewRequest = {
      notification_type_code: NotificationTypes.Sms,
      security_token: recaptcha,
      message: notificationText,
      partner: {
        id: queueElement.partner_id
      },
      contact: {
        first_name: queueElement.customer_first_name || '',
        last_name: queueElement.customer_last_name || '',
        email: queueElement.customer_email,
        phone: queueElement.customer_phone
      },
      queue_id: queueElement.id,
    }

    console.log('request body')
    console.log(requestBody)

    setErrorMessage('');
    api.publicProcessNewNotification(requestBody)
      .then(response => {
        console.log('notification response')
        console.log(response)
        handleSetCustomerNotified(queueElement);
      })
      .catch(error => {
        console.log('send notification error')
        setErrorMessage('Error sending customer notification.')
        console.log(error)
      })
  }

  const handleSetCustomerNotified = (queueElement: QueueElement): void => {
    const requestBody: UpdateQueueElementRequest = {
      customer_notified_at: getCurrentTimestamp()
    }

    setErrorMessage('');
    api.updateQueueElement(queueElement.id, requestBody)
      .then(response => {
        const data = JSON.parse((response as any).data.data);
        updateLocalQueueElement(data);
      })
      .catch(error => {
        console.log('set notified error')
        setErrorMessage('Error recording customer notification.');
        console.log(error)
      })
  }

  const updateQueueElementStatus = (status: QueueStatuses): Promise<void> => {
    return new Promise<any>((resolve, reject) => {
      setIsLoading(true);
      setErrorMessage('');
      api.updateQueueStatus([{ id: order.id, status_code: status }])
        .then(response => {
          // Get the first element of the array, since this is for a single update
          const updatedElement: QueueElement = response.data.data[0];

          updateLocalQueueElement(updatedElement);
          setIsLoading(false);
          resolve(updatedElement);
        })
        .catch(error => {
          console.log('error');
          console.log(error);
          console.log('response');
          console.log(error.response.data.message);
          setErrorMessage('Error updating order status.');
          setIsLoading(false);
          reject();
        })
    })
  }

  const updateLocalQueueElement = (updatedElement: QueueElement): void => {
    const mutatedOrders: Array<QueueElement> = JSON.parse(JSON.stringify(orders));
    const index = mutatedOrders.findIndex(el => el.id === updatedElement.id);
    // only update if order is represented locally
    if (index !== -1) {
      mutatedOrders[index] = updatedElement;
      setOrders(mutatedOrders);
    }
  }

  const handleSetScriptItemNoteInput = (value: string, itemIndex: number) => {
    const mutatedNotesInput: Array<string> = JSON.parse(JSON.stringify(notesInput));
    mutatedNotesInput[itemIndex] = value;
    setNotesInput(mutatedNotesInput);
  }

  const handleSaveScriptItemNoteInput = () => {
    const mutatedQueueItems: Array<QueueItem> = JSON.parse(JSON.stringify(order.queue_items));
    mutatedQueueItems.forEach((item: QueueItem, index: number) => item.queue_item_note = notesInput[index]);
    // todo: update order item notes with API
  }

  // const handleReverseOrder = (): void => {
  //   switch (order.queue_status_code) {
  //     case OrderStatus.AwaitingProcessing:
  //       handleSetDummyOrderStatus(OrderStatus.New);
  //       break;
  //     case QueueStatuses.Dispensed:
  //       handleSetDummyOrderStatus(OrderStatus.AwaitingProcessing);
  //       break;
  //     case QueueStatuses.AwaitingCollection:
  //       handleSetDummyOrderStatus(QueueStatuses.Dispensed);
  //       break;
  //   }
  // }

  const handleCancelOrder = (): void => {
    // const mutatedOrders = JSON.parse(JSON.stringify(orders));
    // const orderIndex = mutatedOrders.findIndex((o: any) => o.id === order.id);
    // mutatedOrders.splice(orderIndex, 1);
    // setOrders(mutatedOrders);

    setErrorMessage('');
    api.updateQueueStatus([{ id: order.id, status_code: QueueStatuses.Cancelled }])
      .then(response => {
        // Get the first element of the array, since this is for a single update
        const updatedElement: QueueElement = response.data.data[0];

        updateLocalQueueElement(updatedElement);
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        console.log('response');
        setErrorMessage('Error cancelling order.');
        console.log(error.response.data.message);
      })
  }

  const handleSetDummyOrderStatus = async (status: string): Promise<any> => {
    // const mutatedOrders = JSON.parse(JSON.stringify(orders));
    // const orderIndex = mutatedOrders.findIndex((o: any) => o.id === order.id);
    // mutatedOrders[orderIndex].status_code = status;
    // setOrders(mutatedOrders);

    return new Promise<any>((resolve, reject) => {
      const requestBody: IRequestBodyUpdateOrder = {
        status_code: status as OrderStatus
      }

      setIsLoading(true);
      apiHelper.updateOrder(order.id, requestBody).then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          // onRefresh();
          // setShowSuccess(true);
          // setTimeout(() => {
          //   handleClose();
          // }, successDuration);
          resolve(response.body.data);
          const data = typeof response.body.data === "string"
            ? JSON.parse(response.body.data)
            : response.body.data;
          updateLocalData(data);
        } else {
          // setErrorMessage(`Error updating delivery method: ${response.body.message}`);
          reject(response.body.message);
        }
      });
    })
  }

  const updateLocalData = (updatedOrder: any): void => {
    const mutatedOrders = JSON.parse(JSON.stringify(orders));
    const orderIndex = mutatedOrders.findIndex((o: any) => o.id === order.id);
    // only update if order is represented locally
    if (orderIndex !== -1) {
      mutatedOrders[orderIndex] = updatedOrder;
      setOrders(mutatedOrders);
    }
  }

  const handlePrintCustomerDocket = useReactToPrint({
    content: () => customerPrintRef.current,
    bodyClass: "printable_visible",
  });

  const handlePrintPharmacistDocket = useReactToPrint({
    content: () => pharmacistPrintRef.current,
    bodyClass: "printable_visible",
  });

  // computed
  const primaryButtonLabel = (): string => {
    switch (order.queue_status_code) {
      case QueueStatuses.New:
      case QueueStatuses.Dispensed:
        return 'Ready for Collection';
      case QueueStatuses.DispensingInProgress:
        return 'Mark as Dispensed';
      case QueueStatuses.AwaitingCollection:
        return 'Begin Collection';
      default:
        return '';
    }
  }

  // const prevStatusLabel = (): string => {
  //   switch (order.queue_status_code) {
  //     case 'dispensing':
  //       return 'Incoming Queue';
  //     case 'pre-collection':
  //       return 'Dispensing Queue';
  //     case 'awaiting-collection':
  //       return 'Pre-Collection';
  //     default:
  //       return '';
  //   }
  // }

  const dropdownMenuItems: Array<DropdownMenuItem> = [
    // {
    //   id: 'editCustomerDetails',
    //   label: 'Edit customer details',
    //   onClick: () => { setShowEditDetailsModal(true) },
    // },
    {
      id: 'addNotes',
      label: 'Add order notes',
      onClick: () => { setShowNotesModal(true) },
    },
    {
      id: 'sendNotification',
      label: 'Send message to customer',
      onClick: () => { setShowNotificationModal(true) },
    },
    {
      id: 'printCustomerDocket',
      label: 'Print customer docket',
      onClick: () => { handlePrintCustomerDocket() },
    },
    {
      id: 'printPharmacistDocket',
      label: 'Print pharmacist docket',
      onClick: () => { handlePrintPharmacistDocket() },
    },
    ...((order.queue_status_code === QueueStatuses.New && !order.is_on_hold) ? [{
      id: 'hold_set_true',
      label: `Place order on hold`,
      onClick: () => { handleUpdateQueueElement({ is_on_hold: true }) },
    }] : []),
    // ...(order.queue_status_code === QueueStatuses.New ? [{
    //   id: 'pre_collection',
    //   label: `Send to Pre-Collection`,
    //   onClick: () => { updateQueueElementStatus(QueueStatuses.Dispensed) },
    // }] : []),
    ...((order.queue_status_code === QueueStatuses.New && order.is_on_hold) ? [{
      id: 'hold_set_false',
      label: `Mark order as active`,
      onClick: () => { handleUpdateQueueElement({ is_on_hold: false }) },
    }] : []),
    ...(!order.is_on_dashboard ? [{
      id: 'dashboard_set_true',
      label: `Show order on dashboard`,
      onClick: () => { handleUpdateQueueElement({ is_on_dashboard: true }) },
    }] : []),
    ...(order.is_on_dashboard ? [{
      id: 'dashboard_set_false',
      label: `Hide order from dashboard`,
      onClick: () => { handleUpdateQueueElement({ is_on_dashboard: false }) },
    }] : []),
    {
      id: 'cancel',
      label: 'Cancel order',
      onClick: () => { handleCancelOrder() },
    },
  ];

  const handleUpdateQueueElement = (requestBody: ProcessQueueElementUpdateRequest): void => {
    setErrorMessage('');
    api.processQueueElementUpdate(order.id, requestBody)
      .then(response => {
        console.log('response');
        console.log(response);
        const data = JSON.parse((response as any).data.data);
        console.log('data');
        console.log(data);
        updateLocalQueueElement(data);
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        console.log('response');
        console.log(error.response.data.message);
        setErrorMessage('Error updating order.');
      })
  }

  return (
    <>
      <StyledOrder className={`${order.requires_consultation && 'scriptQueue_requiresCounselling'}`} deliveryType={order.requires_consultation ? 'scriptQueue_requiresCounselling' : 'scriptQueue_inStore'}>
        {/* absolute positioned elements */}
        <StyledTimeAgo className="Order_timeAgo italic" urgency={OrderUrgency.Standard}>
          <TimeAgo isoDate={order.created_at} />
        </StyledTimeAgo>

        <div className="Order_menu">
          <DropdownMenu items={dropdownMenuItems} />
        </div>

        {/* {order.queue_status_code === 'incoming' &&
          <div className="Order_editDetails button">
            <img src={editBlueIcon} alt="Edit details" />
            <p className="bold">Edit Details</p>
          </div>
        } */}

        {/* relative positioned elements */}
        <div className="Order_badgeContainer">
          <Badge type="scriptQueue_inStore" />
          {order.requires_consultation &&
            <Badge type="scriptQueue_requiresCounselling" />
          }
          {!order.is_on_dashboard &&
            <Badge type="scriptQueue_hiddenDashboard" />
          }
        </div>

        <div className="Order_tags">
          {(
            order.queue_status_code === QueueStatuses.Dispensed ||
            order.queue_status_code === QueueStatuses.AwaitingCollection ||
            order.queue_status_code === QueueStatuses.Collected
          ) &&
            <p>
              <img src={tagCheckIcon} alt="" draggable="false" />
              Dispensed by Pharmacist
            </p>
          }
          {(
            // order.queue_status_code === QueueStatuses.AwaitingCollection ||
            // order.queue_status_code === QueueStatuses.Collected
            order.customer_notified_at &&
            order.queue_status_code === QueueStatuses.AwaitingCollection
          ) &&
            <p>
              <img src={tagCheckIcon} alt="" draggable="false" />
              Collection message sent to customer
            </p>
          }
          {order.queue_status_code === QueueStatuses.Collected &&
            <p>
              <img src={tagCheckIcon} alt="" draggable="false" />
              Collected
            </p>
          }
          {order.queue_status_code === QueueStatuses.Cancelled &&
            <p>
              <img src={tagCrossIcon} alt="" draggable="false" />
              Cancelled
            </p>
          }
        </div>

        <div style={{ position: 'relative' }}>
          <ScriptDetails order={order} />
          <Accordion label="Script Details" chevronIcon="plus_minus" chevronText="expand_hide" defaultOpen>
            <div className="scriptDetails">
              <>
                {order.queue_items?.map((item: QueueItem, i: number) => {
                  return (
                    <div className="scriptContainer" key={`scriptContainer-${item.id}`}>
                      <p className="bold header">{item.prescription_name}</p>
                      {item.queue_item_type_code === QueueItemTypes.ErxPaperScript &&
                        <Alert type={AlertType.Important} icon={AlertIcon.ExclamationRedAlt} small>
                          <p>The physical script must be collected</p>
                        </Alert>
                      }
                      {item.queue_item_type_code !== QueueItemTypes.PaperScript ?
                        <>
                          <p className="details">
                            <span className="bold">Token:</span> {item.prescription_token}<br />
                            <span className="bold">Type:</span> {item.queue_item_type_code === QueueItemTypes.ErxPaperScript ? 'eRx Paper Script' : 'E-Script'}<br />
                            {item.brand_substitution_permitted ?
                              <>
                                <span className="bold">Brand Substitution Permitted</span> <img src={tagCheckIcon} alt="" draggable="false" /><br />
                              </>
                              :
                              <>
                                <span className="bold">Brand Substitution <span className="red">NOT</span> Permitted</span> <img src={tagCrossIcon} alt="" draggable="false" /><br />
                              </>
                            }
                            <span className="bold">Supply:</span> {item.prescription_repeats !== null ? `${item.prescription_repeats} remaining` : '-'}<br />
                            <span className="bold">Name:</span> {item.prescription_patient_name || '-'}<br />
                            {item.queue_item_note ?
                              <>
                                <span className="bold">Script Notes Added</span> <img src={tagCheckIcon} alt="" draggable="false" />
                              </>
                              :
                              <>
                                <span className="bold">No Script Notes Added</span>
                              </>
                            }
                          </p>
                          {item.queue_item_note &&
                            <TextArea id="notes-input" small urgent value={notesInput![i]} maxLength={512} onChange={(e: ChangeEvent) => handleSetScriptItemNoteInput((e.target as HTMLTextAreaElement).value, i)} />
                          }
                        </>
                        :
                        <>
                          <p className="bold header">Paper Script x {item.qty}</p>
                          {item.queue_item_note &&
                            <TextArea id="notes-input" small urgent value={notesInput![i]} maxLength={512} onChange={(e: ChangeEvent) => handleSetScriptItemNoteInput((e.target as HTMLTextAreaElement).value, i)} />
                          }
                        </>
                      }
                    </div>
                  )
                })}
              </>
            </div>
          </Accordion>
          {(order.queue_note !== '' && order.queue_note !== null) &&
            <OrderNotes order={order} onEditNote={() => setShowNotesModal(true)} />
          }
        </div>

        {/* buttons */}
        {order.queue_state_code !== QueueStates.Complete &&
          <Button type={ButtonType.Primary} text={primaryButtonLabel()} onClick={handleProcessOrder} loading={isLoading} />
        }

        {errorMessage &&
          <div style={{ marginTop: '18px' }}>
            <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
              <p>{errorMessage}<br /><DefaultErrorMessage /></p>
            </Alert>
          </div>
        }

        {/* printable dockets */}
        <div className="printable_hidden" ref={customerPrintRef}>
          <ScriptDocket type={ScriptDocketType.Customer} order={order} config={config} />
        </div>

        <div className="printable_hidden" ref={pharmacistPrintRef}>
          <ScriptDocket type={ScriptDocketType.Pharmacist} order={order} config={config} />
        </div>
      </StyledOrder>

      <ScriptQueueConfirmationModal
        show={showConfirmationModal}
        order={order}
        setDummyStatus={handleSetDummyOrderStatus}
        onClose={() => setShowConfirmationModal(false)}
        orders={orders}
        setOrders={setOrders}
        user={user}
        config={config}
        onComplete={handleMarkAwaitingCollection}
      />
      <ScriptQueueNotificationModal show={showNotificationModal} user={user!} order={order} onClose={() => setShowNotificationModal(false)} location={location} />
      <ScriptQueueNotesModal show={showNotesModal} order={order} onClose={() => setShowNotesModal(false)} onRefresh={onRefresh} />
    </>
  );
}

const StyledOrder = styled.div<{ deliveryType: OrderDeliveryType | string }>`
  position: relative;
  width: 100%;
  height: fit-content;
  min-height: 252px;
  margin: 25px 0;
  padding: 52px 52px 46px 52px;
  
  border-radius: 6px;
  background: white;
  box-shadow: 0 0 5px 0 rgba(180, 180, 180, 0.25);
  overflow: hidden;
  z-index: 0;

  &:last-child {
    margin-bottom: 0;
  }

  // HIGHLIGHT
  &.highlight {
    border-top: 1px solid ${colours.alert};
    border-right: 1px solid ${colours.alert};
    border-bottom: 1px solid ${colours.alert};
  }

  &.scriptQueue_requiresCounselling {
    background-color: #FFF9FA;
    
    .scriptContainer {
      background: white;
    }
  }

  // LEFT GRADIENT
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 10px;
    ${props => handleDeliveryType(props.deliveryType)};
  }

  // DROPDOWN MENU
  .Order_menu {
    position: absolute;
    top: 18px;
    right: 54px;
  }
  
  // BADGES
  .Order_badgeContainer {
    display: flow;
    margin-bottom: -10px;

    .Badge {
      display: inline-flex;
      min-width: fit-content;
      margin: 0 10px 10px 0;
    }
  }

  // HISTORY
  .Order_history {
    position: absolute;
    top: 1px;
    right: 0;

    padding: 8px;
    margin-right: -8px;
    margin-top: -8px;
  }

  // TAGS
  .Order_tags {
    display: flex;
    align-items: center;
    margin-top: 13px;

    p {
      display: flex;
      align-items: center;
      font-family: "Mulish Bold";
      height: 24px;
      font-size: 0.625rem; // 10px
      margin: 0 22px 0 0;

      img {
        margin-right: 5px;
      }
    }
  }

  // BUTTON
  button {
    margin-top: 33px;
  }

  .Button_secondary {
    margin-top: 20px;
  }

  .scriptDetails {
    color: black;
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 6px;

    .scriptContainer {
      background: #F8F8F8;
      border-radius: 6px;
      padding: 14px;

      &:first-child {
        margin-top: 10px;
      }

      p.header {
        font-size: 0.875rem; // 14px
        margin: 0;
      }

      p.details {
        margin: 5px 0 0 0;
        font-size: 0.75rem; // 12px
        column-count: 2;
        line-height: 1.6;

        img {
          margin-bottom: -2px;
          margin-left: 3px;
        }

        .red {
          color: #C72025;
        }
      }

      .TextArea {
        margin-bottom: 0;
      }

      .Alert {
        background: #FFEEEE;
        margin: 6px 0 10px 0;
        width: fit-content;
        p {
          color: #ED2929;
        }
      }
    }
  }

  // SCRIPT QUEUE CLASSES
  .Order_editDetails {
    position: absolute;
    right: 53px;
    top: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 3px;
    z-index: 1;
    padding: 0 3px;

    img {
      width: 14px;
      height: 14px;
      margin-bottom: 2px;
    }

    p {
      font-size: 0.625rem; // 10px
      color: #2EC0DF;
    }
  }

  .Order_notes {
    border-top: 1px solid #DADADA;
  }
`

const TelehealthModal = styled.div`
  .Modal {
    .Heading {
      margin: 50px 0 40px 0;
    }
    .Button_primary {
      margin: 40px 0 25px 0;
    }
    .successMessage {
      .Alert {
        margin: -19px 0 -33px 0;
      }
    }
  }
`

const handleDeliveryType = (deliveryType: string): string => {
  switch (deliveryType) {
    case 'scriptQueue_inStore':
      return 'background: linear-gradient(to bottom, #68D4F8, #6E94DD 90%);'
    case 'scriptQueue_requiresCounselling':
      return 'background: linear-gradient(to bottom, #FF5A99, #FF1E2F 90%);'
    default:
      return '';
  }
};

const StyledTimeAgo = styled.p<{ urgency: OrderUrgency }>`
  position: absolute;
  top: 52px;
  right: 54px;
  width: fit-content;
  margin: 0;
  padding: 5px 10px;
  font-size: 0.625rem; // 10px
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  ${props => handleUrgency(props.urgency)};
`

const handleUrgency = (urgency: OrderUrgency): string => {
  switch (urgency) {
    case OrderUrgency.Standard:
      return `
        background: #FCFCFC;
        border-color: #EFEFEF;
        color: #AEAEAE;
      `;
    case OrderUrgency.Warning:
      return `
        background: #FDEFEB;
        border-color: #FEE1D1;
        color: #E5721E;
      `;
    case OrderUrgency.Overdue:
      return `
        background: #FDEBEF;
        border-color: #FED1D7;
        color: ${colours.alert};
      `;
    default:
      return '';
  }
};