import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, Location } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { boxShadows, transitions } from '../../assets/css/variables';
import styled from 'styled-components';

import { UserRoleCode } from '../../types/IUsers';
import { Product } from '../../types/Products';

import { useDummyData } from '../../context/DummyDataProvider';
import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from "../../common/ApiHelper/ApiHelper";
import { SparrowHubApiInterface } from 'sparrowhub-client-axios';
import { ILocation } from '../../types/ILocations';
import { Environment, currentEnvironment } from '../../helpers/utils';

import { StrongProCampaignModal } from "../StrongProCampaignModal/StrongProCampaignModal";

import closeIcon from '../../assets/images/icons/Close.svg';
import backIcon from '../../assets/images/icons/BackArrow.svg';
import ordersIcon from '../../assets/images/icons/Orders.svg';
import createOrderIcon from '../../assets/images/icons/CreateOrder.svg';
// import phoneIcon from '../../assets/images/icons/Phone.svg';
// import reportsIcon from '../../assets/images/icons/Reports.svg';
// import lockIcon from '../../assets/images/icons/Lock.svg';
import sparrowsend from '../../assets/images/icons/SparrowSend.png';
import sparrowphone from '../../assets/images/icons/SparrowPhone.png';
import sparrowconsult from '../../assets/images/icons/SparrowConsult.png';
import sparrowinsight from '../../assets/images/icons/SparrowInsight.png';
import userIcon from '../../assets/images/icons/User.svg';
import usersIcon from '../../assets/images/icons/Users.svg';
import storeIcon from '../../assets/images/icons/Store.svg';
import changeIcon from '../../assets/images/icons/Loop.svg';
import logoutIcon from '../../assets/images/icons/Logout.svg';
import shipmentHistoryIcon from '../../assets/images/icons/ShipmentHistory.svg';
import queueIcon from '../../assets/images/icons/Queue.svg';
import archivedIcon from '../../assets/images/icons/Archived.svg';
import dashboardIcon from '../../assets/images/icons/Dashboard.svg';
import strongroomIcon from '../../assets/images/icons/StrongRoom.png';

import thumbnailGreenery from '../../assets/images/backgrounds/ThumbnailGreenery.png';
import thumbnailMountains from '../../assets/images/backgrounds/ThumbnailMountains.png';
import thumbnailGradient from '../../assets/images/backgrounds/ThumbnailGradient.png';
import thumbnailSky from '../../assets/images/backgrounds/ThumbnailSky.png';
import thumbnailNone from '../../assets/images/backgrounds/ThumbnailNone.png';


enum menuView {
  Default = 'default',
  ChangeBackground = 'changeBackground'
}

type MenuOption = {
  id: string
  label: string
  icon?: string
  requiredUserRoles?: Array<UserRoleCode>
  requiredEnvironments?: Array<Environment>
  dummyOnly?: boolean
  requiredProduct?: Product
  position: 'top' | 'bottom'
  subOption?: boolean
  onClick: Function
}

type BackgroundOption = {
  id: string,
  thumbnailSrc: string
}

type MenuProps = {
  visible: boolean,
  userRole: UserRoleCode | undefined,
  location: ILocation | null,
  onClose: Function,
  onSelectLocation: Function,
  onSelectUser: Function,
  onLogout: Function
}

export const Menu: FunctionComponent<MenuProps> = ({ visible, userRole, location, onClose, onSelectLocation, onSelectUser, onLogout }) => {
  const navigate = useNavigate();
  const domLocation: Location = useLocation();
  const transitionRef = useRef(null);

  // get API context
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();
  const dummyData: any = useDummyData();

  const [currentMenuView, setCurrentMenuView] = useState(menuView.Default);
  const [currentBackgroundClass, setCurrentBackgroundClass] = useState('');

  const [showStrongProCampaignModal, setShowStrongProCampaignModal] = useState(false);

  // add and remove mouse event listeners on mount/unmount
  useEffect(() => {
    document.addEventListener('pointerdown', handleClickOutside);
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    }
  })

  const handleClickOutside = (event: PointerEvent): void => {
    if (menuRef && !menuRef.contains(event.target as Node) && visible) {
      onClose();
    }
  }

  let menuRef: HTMLDivElement | null;
  const initClickOutside = (el: HTMLDivElement | null): void => {
    if (el) {
      menuRef = el;
    }
  }

  // set thumbnail from current background
  useEffect(() => {
    const backgroundClass = localStorage.getItem('Scription_backgroundImage');
    if (backgroundClass !== null && backgroundClass !== undefined) {
      setCurrentBackgroundClass(backgroundClass);
    } else {
      setCurrentBackgroundClass('background_none');
    }
  }, []);
  
  const backgroundOptions: Array<BackgroundOption> = [
    {
      id: 'greenery',
      thumbnailSrc: thumbnailGreenery
    },
    {
      id: 'mountains',
      thumbnailSrc: thumbnailMountains
    },
    {
      id: 'gradient',
      thumbnailSrc: thumbnailGradient
    },
    {
      id: 'sky',
      thumbnailSrc: thumbnailSky
    },
    {
      id: 'none',
      thumbnailSrc: thumbnailNone
    },
  ]

  const currentBackgroundIcon = (): string => {
    return backgroundOptions.find(option => currentBackgroundClass === `background_${option.id}`)?.thumbnailSrc || '';
  }
  
  const menuOptions: Array<MenuOption> = [
    // top options
    // {
    //   id: 'openOrders',
    //   label: 'SparrowSend',
    //   icon: sparrowsend,
    //   position: 'top',
    //   onClick: () => {
    //     navigate('/send/orders');
    //   },
    // },
    {
      id: 'openOrders',
      label: 'All Orders',
      icon: ordersIcon,
      position: 'top',
      requiredProduct: Product.SparrowSend,
      onClick: () => {
        navigate('/send/orders');
      },
    },
    {
      id: 'shipmentHistory',
      label: 'Shipment History',
      icon: shipmentHistoryIcon,
      position: 'top',
      subOption: true,
      requiredProduct: Product.SparrowSend,
      onClick: () => {
        navigate('/send/shipments');
      },
    },
    {
      id: 'prescriptionQueue',
      label: 'Open Queue',
      icon: queueIcon,
      requiredProduct: Product.SparrowQueue,
      position: 'top',
      onClick: () => {
        navigate('/queue/orders');
        onClose();
      },
    },
    {
      id: 'prescriptionQueueArchived',
      label: 'Archived Orders',
      icon: archivedIcon,
      requiredProduct: Product.SparrowQueue,
      position: 'top',
      onClick: () => {
        navigate('/queue/archived');
        onClose();
      },
    },
    {
      id: 'strongPro',
      label: 'Create StrongPro Campaign',
      icon: strongroomIcon,
      requiredUserRoles: [UserRoleCode.Manager, UserRoleCode.Owner, UserRoleCode.Admin],
      // requiredEnvironments: [Environment.Local, Environment.Staging],
      position: 'top',
      subOption: true,
      onClick: () => {
        setShowStrongProCampaignModal(true);
      },
    },
    {
      id: 'accountDetails',
      label: 'Account Details',
      icon: userIcon,
      requiredUserRoles: [UserRoleCode.Admin],
      dummyOnly: true,
      position: 'top',
      subOption: true,
      onClick: () => {
        console.log('todo: handle Account Details');
      },
    },
    {
      id: 'adminSettings',
      label: 'User Management',
      icon: usersIcon,
      requiredUserRoles: [UserRoleCode.Admin],
      position: 'top',
      subOption: true,
      onClick: () => {
        navigate('/admin');
      },
    },
    // {
    //   id: 'createOrder',
    //   label: 'Create Custom Order',
    //   // icon: sparrowphone,
    //   icon: createOrderIcon,
    //   requiredProduct: Product.SparrowSend,
    //   requiredUserRoles: [UserRoleCode.Admin],
    //   position: 'top',
    //   onClick: () => {
    //     navigate('/send/process/create-order');
    //   },
    // },
    // {
    //   id: 'telehealth',
    //   label: 'SparrowConsult',
    //   icon: sparrowconsult,
    //   requiredUserRoles: [UserRoleCode.Admin],
    //   dummyOnly: true,
    //   position: 'top',
    //   onClick: () => {
    //     navigate('/consult');
    //   },
    // },
    // {
    //   id: 'performanceReports',
    //   label: 'SparrowInsight',
    //   icon: sparrowinsight,
    //   requiredUserRoles: [UserRoleCode.Admin],
    //   dummyOnly: true,
    //   position: 'top',
    //   onClick: () => {
    //     navigate('/insight');
    //   },
    // },
    {
      id: 'changeBackground',
      label: 'Change Background',
      icon: currentBackgroundIcon(),
      position: 'top',
      onClick: () => {
        setCurrentMenuView(menuView.ChangeBackground);
      },
    },
    // bottom options
    {
      id: 'changeLocation',
      label: 'Change Dispatch Store',
      icon: storeIcon,
      position: 'bottom',
      onClick: () => {
        onSelectLocation(null);
        navigate('/select-store');
      },
    },
    {
      id: 'changeUser',
      label: 'Change Users',
      icon: changeIcon,
      requiredUserRoles: [UserRoleCode.Admin],
      dummyOnly: true,
      position: 'bottom',
      onClick: () => {
        onSelectUser(null);
        navigate('/select-user');
      },
    },
    {
      id: 'logOut',
      label: 'Log Out',
      icon: logoutIcon,
      position: 'bottom',
      onClick: () => {
        handleLogout();
      }
    },
  ]

  const handleSetBackground = (id: string): void => {
    const classname = `background_${id}`;

    // set on body element
    let newBodyClasses = Array.from(document.body.classList);
    newBodyClasses = newBodyClasses.filter(classname => !classname.includes('background_'));
    newBodyClasses.push(classname);
    document.body.className = newBodyClasses.join(' ');

    // set in state
    setCurrentBackgroundClass(classname);

    // save to local storage
    localStorage.setItem('Scription_backgroundImage', classname);

    // close menu
    setCurrentMenuView(menuView.Default);
    onClose();
  }

  const handleLogout = async () => {
    if (dummyData.state.useDummyData) {
      onLogout();
      navigate('/logout');
    } else {
      apiHelper.logoutUser().then((response) => {
        if (response.status === 200) {
          onLogout();
          navigate('/logout');
        } else {
          console.log(response);
        }
      });
    }
  }

  const currentProduct = (): Product => {
    if (domLocation.pathname.includes('/send')) {
      return Product.SparrowSend;
    } else if (domLocation.pathname.includes('/phone')) {
      return Product.SparrowPhone;
    } else if (domLocation.pathname.includes('/insight')) {
      return Product.SparrowInsight;
    } else if (domLocation.pathname.includes('/consult')) {
      return Product.SparrowConsult;
    } else if (domLocation.pathname.includes('/queue')) {
      return Product.SparrowQueue;
    } else {
      return Product.SparrowHub;
    }
  }

  currentProduct();

  return (
    <StyledMenu className={`Menu ${visible ? '' : 'hidden'}`} ref={el => initClickOutside(el)}>
      <SwitchTransition>
        <CSSTransition
          key={currentMenuView}
          nodeRef={transitionRef}
          timeout={200}
          classNames="fade"
          unmountOnExit
        >
          <div className="Menu_parent" ref={transitionRef}>
            {currentMenuView === menuView.Default &&
              <div className="Menu_default">
                <img className="Menu_close button" alt="Close nav menu" src={closeIcon} onClick={() => onClose()} draggable="false" />

                {menuOptions.filter(option => option.dummyOnly !== true || dummyData.state.useDummyData).map((option: MenuOption, i: number) => {
                  if (
                    (!option.requiredUserRoles || (userRole && option.requiredUserRoles.includes(userRole))) &&
                    (!option.requiredEnvironments || (userRole && option.requiredEnvironments.includes(currentEnvironment()))) &&
                    (!option.requiredProduct || option.requiredProduct === currentProduct())
                  ) {
                    return (
                      <div className={`Menu_option ${option.position === 'bottom' ? 'Menu_optionBottom' : ''}`} key={`option_${option.id}`} onClick={() => option.onClick()}>
                        {/* <div className={`${option.subOption ? 'Menu_subOption' : ''}`}> */}
                        <div>
                          <img className={option.id === 'changeBackground' ? 'Menu_backgroundIcon' : ''} src={option.icon} alt="" draggable="false" />
                          <p>{option.label}</p>
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            }

            {currentMenuView === menuView.ChangeBackground &&
              <div className="Menu_changeBackground">
                <img className="Menu_back button" alt="Back to nav menu" src={backIcon} onClick={() => setCurrentMenuView(menuView.Default)} draggable="false" />
                
                <div className="Menu_option">
                  <img className="Menu_backgroundIcon" src={currentBackgroundIcon()} alt="" draggable="false" />
                  <p>Change Background</p>
                </div>
                
                <div className="Menu_backgroundThumbnails">
                  {backgroundOptions.map((option: BackgroundOption, i: number) => {
                    return (
                      <img
                        className={`Menu_backgroundThumbnail ${currentBackgroundClass === `background_${option.id}` ? 'active' : ''}`}
                        key={`backgroundThumbnail_${option.id}`}
                        src={option.thumbnailSrc}
                        alt={`Select ${option.id} background`}
                        draggable="false"
                        onClick={() => handleSetBackground(option.id)}
                      />
                    )
                  })}
                </div>
              </div>
            }
          </div>
        </CSSTransition>
      </SwitchTransition>

      <StrongProCampaignModal show={showStrongProCampaignModal} location={location!} onClose={() => { onClose(); setShowStrongProCampaignModal(false) }} />
    </StyledMenu>
  );
}

const StyledMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  width: 331px;
  height: 100vh;
  background: white;
  border-radius: 6px;
  padding: 65px 19px 34px 19px;
  box-shadow: -4px 4px 4px 0 rgba(0, 0, 0, 0.1);
  transition: transform ${transitions.default};

  &.hidden {
    transform: translateX(340px);
  }

  .Menu_parent {
    width: 100%;
    height: 100%;

    .Menu_close {
      position: absolute;
      width: 30px;
      top: 25px;
      right: 32px;
    }

    .Menu_back {
      position: absolute;
      width: 17px;
      top: 35px;
      left: 41px;
    }

    .Menu_backgroundIcon {
      padding: 2px;
      border-radius: 6px;
    }

    .Menu_default {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .Menu_option {
        height: 53px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 6px;
        transition: background-color ${transitions.fast};

        img {
          margin: 0 16px 0 22px;
          width: 22px;
          user-select: none;
        }

        p {
          font-family: "Mulish Medium";
          font-size: 0.875rem; // 14px
          user-select: none;
        }

        &:hover {
          background-color: #DADADA;
        }
        
        &.Menu_optionBottom {
          height: 45px;
          margin-top: auto;

          p {
            font-family: "Mulish Bold";
            font-size: 1rem; // 16px
          }

          & ~ .Menu_optionBottom {
            margin-top: 0;
          }
        };
       
        div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          &.Menu_subOption {
            padding-left: 2rem;
          }
        }
      }
    }

    .Menu_changeBackground {
      .Menu_option {
        height: 53px;
        display: flex;
        align-items: center;

        img {
          margin: 0 16px 0 22px;
          width: 22px;
        }

        p {
          font-family: "Mulish Medium";
          font-size: 0.875rem; // 14px
          user-select: none;
        }
      }

      .Menu_backgroundThumbnails {
        padding: 0 23px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 7px;
        grid-gap: 7px;
        margin-top: 13px;

        .Menu_backgroundThumbnail {
          cursor: pointer;
          width: 117px;
          height: auto;
          border-radius: 8px;
          ${boxShadows.default}
          transition: box-shadow ${transitions.default};
    
          &:hover {
            ${boxShadows.hover}
          }

          &.active {
            border: 2px solid black;
          }
        }
      }
    }
  }
`