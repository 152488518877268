import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { IOrder, OrderStatus, OrderState, OrderDeliveryType, IOrders } from '../../types/IOrders';
import { IUser } from '../../types/IUsers';
import { IDeliveries, IDelivery } from '../../types/IDelivery';

import { IRequestBodyProcessDeliveryCompletion, IRequestBodySendNotification } from '../../types/IRequests';
import { NotificationType } from '../../types/INotifications';
import { IResponseSendNotification } from '../../types/IResponses';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { SparrowHubApiInterface } from 'sparrowhub-client-axios';
import { useDummyData } from '../../context/DummyDataProvider';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { OrderBadges } from '../OrderBadges/OrderBadges';
import { DefaultErrorMessage } from '../DefaultErrorMessage/DefaultErrorMessage';


type CustomDeliveryModalProps = {
  show: boolean
  user: IUser
  order: IOrder
  deliveries: IDeliveries
  onClose?: Function
  onRefresh: Function
  orders: IOrders
  setOrders: Function
}

export const CustomDeliveryModal: FunctionComponent<CustomDeliveryModalProps> = ({ show, user, order, deliveries, onClose, onRefresh, orders, setOrders }) => {
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();
  const dummyData = useDummyData();
  
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // methods
  const successDuration = 3000;
  const handleCompleteDelivery = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      setErrorMessage('');
      if (dummyData.state.useDummyData) {
        // dummyData.mutations.setOrderStatus(order.id, OrderStatus.Complete);
        // dummyData.mutations.setOrderState(order.id, OrderState.Complete);
        // onRefresh();

        const mutatedOrders = JSON.parse(JSON.stringify(orders));
        const orderIndex = mutatedOrders.findIndex((o: any) => o.id === order.id);
        mutatedOrders[orderIndex].status_code = OrderStatus.Complete;
        mutatedOrders[orderIndex].state_code = OrderState.Complete;

        setShowSuccess(true);
        setTimeout(() => {
          handleClose();

          sendNotification();

          setTimeout(() => {
            setOrders(mutatedOrders);
          }, transitionDuration);
        }, successDuration);
        resolve('');
      } else {
        const promises = deliveries.map((delivery: IDelivery) => {
          const requestBody: IRequestBodyProcessDeliveryCompletion = {
            user_id: user.id,
          };
          return apiHelper.processDeliveryCompletion(delivery.id, requestBody)
        })

        setIsLoading(true);
        Promise.all(promises).then((responses) => {
          setIsLoading(false);
          if (responses.every(response => response.status === 200)) {
            onRefresh();
            setShowSuccess(true);
            setTimeout(() => {
              handleClose();
            }, successDuration);
          } else {
            setErrorMessage(`Error updating order.`);
            reject();
          }
        });
      }
    });
  }

  const sendNotification = async (): Promise<any> => {
    console.log('sendNotification');
    return new Promise<any>((resolve, reject) => {
      const notificationText = `Your tracking link is https://auspost.com.au/mypost/beta/track/details/36A8E5000301\n\nRegards, Smith’s Pharmacy`;

      const requestBody: IRequestBodySendNotification = {
        message: notificationText,
        contact: order.delivery_phone,
        notification_type_code: NotificationType.Sms,
        user_id: user!.id,
        partner_id: user!.partner_id
      }
      apiHelper.sendNotification(requestBody).then((response: IResponseSendNotification) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          console.warn(response)
          resolve(response);
        }
      });
    })
  }

  const transitionDuration = 300;
  const handleClose = (): void => {
    setTimeout(() => {
      setShowSuccess(false);
    }, transitionDuration);

    if (onClose) onClose();
  }

  // computed
  const targetStatusString = (): string => {
    if (order.status_code === OrderStatus.AwaitingCourier) {
      return 'picked up'
    } else if (order.status_code === OrderStatus.AwaitingDropoff) {
      return 'dropped off'
    } else {
      return 'complete'
    }
  }

  return (
    <StyledCustomDeliveryModal className="CustomDeliveryModal">
      <Modal show={show}>
        {!showSuccess &&
          <>
            <Heading heading={`Are you sure you want to mark this order as ${targetStatusString()}?`} />
            <div className="CustomDeliveryModal_details">
              <OrderBadges order={order} />
              <OrderDetails order={order} phone email bold={false} />
            </div>
            {errorMessage &&
              <div style={{ marginTop: '30px', marginBottom: '-20px' }}>
                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                  <p>{errorMessage}<br/><DefaultErrorMessage /></p>
                </Alert>
              </div>
            }
            <Button type={ButtonType.Primary} text="Yes, I'm Sure" onClick={handleCompleteDelivery} loading={isLoading} />
            <Button type={ButtonType.Secondary} text="Cancel" onClick={handleClose} />
          </>
        }
        {showSuccess &&
          <Alert type={AlertType.PositiveSecondary} successModal>
            <p>This order has been marked as {targetStatusString()} and can be found in <span className="extrabold">Closed Orders &gt; Completed Orders</span></p>
            {/* <p>This order has been marked as delivered and can be found in <span className="extrabold">Closed Orders &gt; Completed Orders</span></p> */}
          </Alert>
        }
      </Modal>
    </StyledCustomDeliveryModal>
  );
}

const StyledCustomDeliveryModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;

    .CustomDeliveryModal_details {
      margin-top: 28px;

      .Badge {
        display: inline-flex;
        margin-right: 8px;
      }
    }

    .CustomDeliveryModal_grid {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      grid-gap: 25px 14px;
      gap: 25px 14px;
      margin: 50px 0;

      .Button {
        width: 100%;
        height: 128px;
        margin: 0 !important;
      }
    }
  }

  .Button_primary {
    margin-top: 50px;
    margin-bottom: 23px;
  }
`