import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { IOrder, OrderDeliveryType } from '../../types/IOrders';
import { ILocations } from '../../types/ILocations';
import { formatOrderAddress, getOrderNumber } from '../../helpers/utils';

import phoneIcon from '../../assets/images/icons/Phone.svg';
import mailIcon from '../../assets/images/icons/Mail.svg';
import pinIcon from '../../assets/images/icons/Pin.svg';
import shoppingBagIcon from '../../assets/images/icons/ShoppingBag.svg';


type OrderDetailsProps = {
  order: IOrder | any
  partnerLocations?: ILocations
  name?: boolean
  phone?: boolean
  email?: boolean
  address?: boolean
  billingAddress?: boolean
  location?: boolean
  scripts?: boolean
  cart?: boolean
  bold?: boolean
}

export const OrderDetails: FunctionComponent<OrderDetailsProps> = ({ order, partnerLocations, name, phone, email, address, billingAddress, location, scripts, cart, bold }) => {
  const orderLocation = partnerLocations?.find(location => location.code === order.location_code);

  if (location && !partnerLocations) console.warn('OrderDetails: Cannot display location information without PartnerLocations.')

  return (
    <StyledOrderDetails className="OrderDetails">
      <p className={`OrderDetails_id ${bold ? 'bold' : ''}`}>
        {!cart ?
          <>
            {name ?
              <>
                <span className="OrderDetails_name ellipsis">{`${order.customer_firstname || order.delivery_firstname || order.billing_firstname || ''} ${order.customer_lastname || order.delivery_lastname || order.billing_firstname || ''}`}</span>
                <span className="OrderDetails_divider">|</span>
              </>
            :
              <span className="OrderDetails_name">Order&nbsp;</span>
            }
            <span className="OrderDetails_number">{`${getOrderNumber(order)}`}</span>
          </>
        :
          <>
            {order.delivery_firstname &&
              <span className="OrderDetails_name ellipsis">{`${order.delivery_firstname || ''} ${order.delivery_lastname || ''}`}</span>
            }
            {(!order.delivery_firstname && !phone && !email) &&
              <span className="OrderDetails_name ellipsis" style={{ margin: '15px 0 5px 0' }}>No contact details</span>
            }
          </>
        }
      </p>
      {address &&
        <p className="OrderDetails_detail OrderDetails_address">
          <img src={pinIcon} alt="Address" style={{ padding: '0 3px' }} />
          <span>{order.delivery_firstname ? formatOrderAddress(order, 'delivery') : formatOrderAddress(order, 'billing')}</span>
        </p>
      }
      {billingAddress && 
        <p className="OrderDetails_detail OrderDetails_address">
          <img src={pinIcon} alt="Address" style={{ padding: '0 3px' }} />
          <span>{formatOrderAddress(order, 'billing')}</span>
        </p>
      }
      {location && 
        <p className="OrderDetails_detail OrderDetails_address">
          <img src={pinIcon} alt="Address" style={{ padding: '0 3px' }} />
          <span>{orderLocation?.name}</span>
        </p>
      }
      {(phone || email || scripts) &&
        <div className="OrderDetails_contact">
          {phone && 
            <p className="OrderDetails_detail">
              <img src={phoneIcon} alt="Phone" />
              {order.delivery_type_code === OrderDeliveryType.Pickup ? order.billing_phone : order.delivery_phone || order.billing_phone}
            </p>
          }
          {email && 
            <p className="OrderDetails_detail OrderDetails_email ellipsis">
              <img src={mailIcon} alt="Email" />
              <span className="ellipsis">{order.delivery_email || order.billing_email}</span>
            </p>
          }
          {scripts && 
            <p className="OrderDetails_detail OrderDetails_email">
              <img src={shoppingBagIcon} alt="Items" />
              {JSON.parse(order.delivery_city).numScripts !== 0 &&
                <span>{JSON.parse(order.delivery_city).numScripts} paper {JSON.parse(order.delivery_city).numScripts === 1 ? 'script' : 'scripts'}</span>
              }
              {(JSON.parse(order.delivery_city).numScripts !== 0 && JSON.parse(order.delivery_city).numEscripts !== 0) &&
                <span>,&nbsp;</span>
              }
              {JSON.parse(order.delivery_city).numEscripts !== 0 &&
                <span>{JSON.parse(order.delivery_city).numEscripts} e-{JSON.parse(order.delivery_city).numEscripts === 1 ? 'script' : 'scripts'}</span>
              }
            </p>
          }
        </div>
      }
    </StyledOrderDetails>
  );
}

OrderDetails.defaultProps = {
  name: true,
  bold: true
}

const StyledOrderDetails = styled.div`
  .OrderDetails_id {
    margin: 13px 0 10px 0;
    font-size: 1.125rem; // 18px
    width: calc(100% - 40px);
    white-space: nowrap;
    display: flex;

    .OrderDetails_name {
    }

    .OrderDetails_number {
      min-width: fit-content;
    }

    .OrderDetails_divider {
      flex-grow: 0;
      margin: 0 12px;
    }
  }

  .OrderDetails_contact {
    display: flex;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 16px;

    p {
      margin-top: 0;
    }
  }

  .OrderDetails_detail {
    display: flex;
    align-items: center;
    font-size: 1rem; // 16px
    margin-top: 10px;
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: 30px;
    }

    &.OrderDetails_email {
      flex-grow: 1;
      max-width: 60%;
      /* max-width: 200px; */
    }
    
    /* &.OrderDetails_address {
      margin-top: 10px !important;
    } */

    img {
      margin-right: 10px;
    }
  }
`