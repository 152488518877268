import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { boxShadows, transitions, colours } from '../../assets/css/variables';

import arrowIcon from '../../assets/images/icons/ButtonArrow.svg';
import searchIcon from '../../assets/images/icons/Search.svg';
import printIcon from '../../assets/images/icons/Print.svg';
import plusIcon from '../../assets/images/icons/Plus.svg';
import mailIcon from '../../assets/images/icons/Mail.svg';
import dollarIcon from '../../assets/images/icons/ButtonDollar.svg';
import receiptIcon from '../../assets/images/icons/Receipt.svg';
import notesIcon from '../../assets/images/icons/Notes.svg';
import trashIcon from '../../assets/images/icons/Trash.svg';
import ejectIcon from '../../assets/images/icons/Eject.svg';
import shareIcon from '../../assets/images/icons/Share.svg';
import addCircleIcon from '../../assets/images/icons/AddCircle.svg';
import checkOutlinedIcon from '../../assets/images/icons/check-outlined.svg';

export enum ButtonType {
  Primary = "primary",
  Secondary = "secondary",
  AddParcel = "addParcel",
  Search = "search",
  Inline = "inline"
}

export enum ButtonIcon {
  Arrow = 1,
  Search,
  Print,
  Plus,
  Mail,
  Dollar,
  Receipt,
  Notes,
  Trash,
  Eject,
  Share,
  AddCircle,
  CheckOutlined
}

export enum ButtonColour {
  Blue = "Blue",
  Green = "Green",
  Green200 = "Green200",
  Red = "Red",
  RedAlt = "RedAlt",
  StrongPro = "StrongPro"
}

type ButtonProps = {
  type: ButtonType
  text?: string
  label?: string
  icon?: ButtonIcon | undefined
  tall?: boolean
  small?: boolean
  reverse?: boolean
  colour?: ButtonColour
  onClick?: Function
  disabled?: boolean
  loading?: boolean
  submit?: boolean
}

export const Button: FunctionComponent<ButtonProps> = ({ type, text, label, icon, tall, small, reverse, colour, onClick, disabled, loading, submit }) => {
  const handleClick = () => {
    if (!disabled && !loading && onClick) onClick();
  }

  const iconSrc = (icon: ButtonIcon): string => {
    switch (icon) {
      case ButtonIcon.Arrow:
        return arrowIcon;
      case ButtonIcon.Search:
        return searchIcon;
      case ButtonIcon.Print:
        return printIcon;
      case ButtonIcon.Plus:
        return plusIcon;
      case ButtonIcon.Mail:
        return mailIcon;
      case ButtonIcon.Dollar:
        return dollarIcon;
      case ButtonIcon.Receipt:
        return receiptIcon;
      case ButtonIcon.Notes:
        return notesIcon;
      case ButtonIcon.Trash:
        return trashIcon;
      case ButtonIcon.Eject:
        return ejectIcon;
      case ButtonIcon.Share:
        return shareIcon;
      case ButtonIcon.AddCircle:
        return addCircleIcon;
      case ButtonIcon.CheckOutlined:
        return checkOutlinedIcon;
      default:
        return '';
    }
  }

  return (
    <>
      {label && <StyledButtonLabel className="Button_label italic">{label}</StyledButtonLabel>}
      <StyledButton
        className={[
          `Button Button_${type}`,
          `${icon ? 'Button_icon' : ''}`,
          `${text ? 'Button_text' : ''}`,
          `${small ? 'Button_small' : ''}`,
          `${tall ? 'Button_tall' : ''}`,
          `${reverse ? 'Button_reverse' : ''}`,
          `${colour ? `Button_${colour}` : ''}`,
          `${loading ? 'Button_loading' : ''}`
        ].join(' ')}
        onClick={handleClick}
        disabled={disabled || loading}
        type={submit ? 'submit' : 'button'}
      >
        {text}
        {icon && <img src={iconSrc(icon)} alt="" draggable="false" />}
      </StyledButton>
    </>
  );
}

const StyledButtonLabel = styled.label`
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 14px;
`

const StyledButton = styled.button`
  /* GENERAL CSS */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  ${boxShadows.default}
  user-select: none;
  transition:
    box-shadow ${transitions.default},
    transform ${transitions.default},
    opacity ${transitions.default};

  &.Button_text {
    img {
      margin-left: 25px;
    }
  }

  /* STATES */
  &:hover {
    ${boxShadows.hover}
  }

  &:active:not(:disabled) {
    transform: scale(0.975);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  /* TYPES */

  &.Button_primary {
    width: 100%;
    height: 66px;
    background: black;
    color: white;
    line-height:  164%;
    border: none;

    img {
      filter: invert();
    }
  }

  &.Button_secondary {
    width: 100%;
    height: 49px;
    background: white;
    color: black;
    border: 1px solid black;
    line-height:  164%;
  }

  &.Button_icon {
    height: 89px;
  }
  
  &.Button_addParcel {
    width: 100%;
    height: 61px;
    background: white;
    color: black;
    line-height: 164%;
    border: none;
    /* https://kovart.github.io/dashed-border-generator/ */
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23333' stroke-width='2' stroke-dasharray='4%2c 4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px;
  }

  &.Button_search {
    background: black;
    color: white;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &.Button_inline {
    background: black;
    color: white;
    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 36px;
  }

  /* EXTRA MODIFIERS */

  &.Button_small {
    height: 41px;
  }

  &.Button_tall {
    height: 89px;
  }

  &.Button_Blue {
    background-color: ${colours.buttonBlue};
  }

  &.Button_Green {
    background-color: ${colours.buttonGreen};
  }
  
  &.Button_Green200 {
    background-color: ${colours.green200};
  }
  
  &.Button_Red {
    background-color: ${colours.buttonRed};
  }
  
  &.Button_RedAlt {
    background-color: ${colours.buttonRedAlt};
  }
  
  &.Button_StrongPro {
    background-image: linear-gradient(to right, #FE8128, #FF3326);
  }

  &.Button_loading {
    cursor: wait !important;
  }

  &.Button_reverse {
    flex-direction: row-reverse;

    &.Button_text {
      img {
        margin-left: 0px;
        margin-right: 15px;
      }
    }
  }
`