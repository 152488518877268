import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { CourierType, DeliveryModelCode, IOrder, OrderDeliveryType, OrderStatus } from '../../types/IOrders';
import { orderRequiresContact } from '../../helpers/utils';
import { Category } from '../../types/DashboardCategories';

import { Badge } from '../Badge/Badge';


type OrderBadgesProps = {
  order: IOrder
  category?: Category
  compact?: boolean
}

export const OrderBadges: FunctionComponent<OrderBadgesProps> = ({ order, category, compact }) => {
  return (
    <StyledOrderBadges className="OrderBadges">
      {/* Determine whether to use the Amazon or Ebay badge with the `order.platform_payment_method` value */}
      {order.platform_payment_method === 'amazon' && <Badge type={'payment_method_amazon'} iconOnly />}
      {order.platform_payment_method === 'ebay' && <Badge type={'payment_method_ebay'} iconOnly />}
      {(order as any).cart_id && <Badge type={'cart_link'} iconOnly />}
      {order.delivery_type_code === OrderDeliveryType.ExternalPickup && <Badge type={'payment_method_webshop'} iconOnly />}
      
      {order.delivery_type_code &&
        <Badge type={order.delivery_type_code} />
      }
      {(!order.delivery_type_code && (order as any).cart_id) &&
        <Badge type={'cart_payment_only'} />
      }
      {category &&
        <>
          {order.delivery_type_code === OrderDeliveryType.SameDay &&
            <>
              {(category === Category.OpenOrders || category === Category.RequiresManifest) ?
                <Badge type={'courier_doordash'} iconOnly={compact}/>
                :
                <Badge type={'courier_doordash_pickup'} iconOnly={compact}/>
              }
            </>
          }
          {(order.delivery_type_code === OrderDeliveryType.Express || order.delivery_type_code === OrderDeliveryType.Standard) &&
            <>
              {(category === Category.OpenOrders || category === Category.RequiresManifest) ?
                <Badge type={'courier_australiaPost'} iconOnly={compact}/>
                :
                <>
                  {order.delivery_model_code === DeliveryModelCode.Dropoff && <Badge type={'courier_australiaPost_dropoff'} iconOnly={compact} />}
                  {order.delivery_model_code === DeliveryModelCode.Pickup && <Badge type={'courier_australiaPost_pickup'} iconOnly={compact} />}
                </>
              }
            </>
          }
        </>
      }
      {order.status_code === OrderStatus.Archived &&
        <Badge type={'order_archived'} iconOnly={compact}/>
      }
      {orderRequiresContact(order) &&
        <Badge type={'requires_contact'} iconOnly={compact}/>
      }
    </StyledOrderBadges>
  );
}

const StyledOrderBadges = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`