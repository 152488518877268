import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { showNewMessage } from '@intercom/messenger-js-sdk';

type DefaultErrorMessageProps = {
  text?: string
}

export const DefaultErrorMessage: FunctionComponent<DefaultErrorMessageProps> = ({ text }) => {
  return (
    <StyledDefaultErrorMessage>
      {text} <span className="DefaultErrorMessage_link" onClick={() => showNewMessage('')}>here</span>.
    </StyledDefaultErrorMessage>
  );
}

DefaultErrorMessage.defaultProps = {
  text: "Please try again or contact SparrowHub"
}

const StyledDefaultErrorMessage = styled.span`
  .DefaultErrorMessage_link {
    text-decoration: underline;
    cursor: pointer;
  }
`