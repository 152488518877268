import { FunctionComponent, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';

import { IOrder, IOrders, OrderStatus } from '../../types/IOrders';
import { ILocation } from '../../types/ILocations';
import { IRequestBodyUpdateOrder, IRequestBodyProcessCollectionDeliveryBatchByLocation, IRequestBodyProcessDropoffDeliveryBatchByLocation } from '../../types/IRequests';
import { Category, Subcategory } from "../../types/DashboardCategories";
import { IUser } from '../../types/IUsers';
import { IDeliveryBatches } from "../../types/IDeliveryBatches";
import { getCourierLocationByStore } from '../../helpers/utils';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { SparrowHubApiInterface } from 'sparrowhub-client-axios';
import { useDummyData } from '../../context/DummyDataProvider';

import { Process, ProcessOrderStep } from "../../pages/ProcessOrderPage";
import { ProcessSteps } from "../../components/ProcessSteps/ProcessSteps";

import { Heading } from "../Heading/Heading";
import { Alert, AlertType, AlertIcon } from '../Alert/Alert';
import { Button, ButtonIcon, ButtonType, ButtonColour } from '../Button/Button';
// import { SelectionGroup, SelectionGroupInputType, SelectionGroupType, SelectionGroupOption } from '../SelectionGroup/SelectionGroup';
import { DefaultErrorMessage } from '../DefaultErrorMessage/DefaultErrorMessage';

import australiaPostLogo from '../../assets/images/logos/AustraliaPost.png';
import phoneIcon from '../../assets/images/icons/Phone.svg';
import pinIcon from '../../assets/images/icons/Pin.svg';
import dummyManifest from '../../assets/demoDocuments/manifest-smiths.pdf';

// configure react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
};

type ScheduleDeliveryModalProps = {
  process: Process
  steps: Array<ProcessOrderStep>
  currentStepIndex: number
  orders: IOrders
  manifestOrders: IOrders
  setOrders: Function
  location: ILocation
  next: Function
  back: Function
  onComplete: Function
  onRefresh: Function
  user: IUser
  category: Category
  subcategory: Subcategory
}


export const ScheduleDeliveryModal: FunctionComponent<ScheduleDeliveryModalProps> = ({ process, steps, currentStepIndex, orders, manifestOrders, setOrders, user, category, subcategory, location, next, back, onComplete, onRefresh }) => {
  const printRef = useRef(null);
  const dummyData: any = useDummyData();

  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();

  // process data
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [shipmentHistoryBatch, setShipmentHistoryBatch] = useState<IDeliveryBatches[] | null>([]);
  const [currentManifest, setCurrentManifest] = useState(undefined as any | undefined);
  const [numPages, setNumPages] = useState(0);
  // const [shouldPrint, setShouldPrint] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  // computed properties
  const currentStep = steps[currentStepIndex];
  const courierLocation = getCourierLocationByStore(location.code);

  const australiaPostMapSrc = (): string | undefined => {
    return dummyData.state.useDummyData ? '/assets/images/maps/broadway.png' : location.image_dropoff || undefined;
  }

  // methods
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
    // if (shouldPrint) {
    //   setTimeout(() => {
    //     printManifest();
    //     setShouldPrint(false);
    //   }, 500);
    // }
  }

  const printManifest = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'printable_visible'
  })

  const handlePrintManifest = async () => {
    setIsLoading(true);
    // setShouldPrint(true);
    
    if (dummyData.state.useDummyData || currentManifest !== undefined) {
      // printManifest();

      // show fake manifest
      window.open(dummyManifest, '_blank')
      setIsDisabled(false);
    } else {
      await handleLoadManifest();
    }
    setIsLoading(false);
  };

  const handleLoadManifest = async () => {
    setErrorMessage('');
    const response = await ((subcategory === Subcategory.ManifestDropoff) ? apiHelper.processDropoffDeliveriesByLocation(location?.code) : apiHelper.processCollectionDeliveriesByLocation(location?.code));
    try {
      if (response.body.status === 'success' && response.body.data) {
        const data = typeof response.body.data === "string"
          ? JSON.parse(response.body.data)
          : response.body.data;
        
        if (data.length) {
          const deliveryIds = data.map((delivery: IDeliveryBatches) => {
            return delivery.id;
          });
          const requestBody: IRequestBodyProcessCollectionDeliveryBatchByLocation = {
            user_id: user.id,
            delivery_ids: deliveryIds,
          };
          const deliveryBatchResponse = await((subcategory === Subcategory.ManifestPickup) ? apiHelper.processCollectionDeliveryBatchByLocation(location?.code, requestBody) : apiHelper.processDropoffDeliveryBatchByLocation(location?.code, requestBody));
          const deliveryBatchData = typeof deliveryBatchResponse?.body === "string"
            ? JSON.parse(deliveryBatchResponse?.body)
            : deliveryBatchResponse?.body;
    
          if (deliveryBatchData && deliveryBatchData.id) {
            // const id = deliveryBatchData?.map((deliveryBatch: IDeliveryBatches) => {
            //   return deliveryBatch.id 
            // });
    
            // setCurrentManifest(null);
            const manifestResponse = await apiHelper.getDeliveryBatchDocument(deliveryBatchData.id);  
            const manifestUrl = URL.createObjectURL(manifestResponse.body);
            window.open(manifestUrl, '_blank')
            setIsDisabled(false);
            // setCurrentManifest(manifestUrl);
          } else {
            throw new Error(response.body.message);
          }
        }
      } else {
        throw new Error(response.body.message);
      }
    } catch (error) {
      setErrorMessage(`Error generating manifest document: ${error}`);
    }
  }

  const handleCompleteProcess = (): void => {
    if (dummyData.state.useDummyData) {
      const status = process === Process.SchedulePickup ? OrderStatus.AwaitingCourier : OrderStatus.AwaitingDropoff;
      const mutatedOrders = JSON.parse(JSON.stringify(orders));
      manifestOrders.forEach((order: IOrder) => {
        // dummyData.mutations.setOrderStatus(order.id, status);
        const orderIndex = mutatedOrders.findIndex((o: any) => o.id === order.id);
        mutatedOrders[orderIndex].status_code = status;
        setOrders(mutatedOrders);
      });
    }

    const successDuration = 3000;
    setShowSuccessMessage(true);
    onRefresh();
    setTimeout(() => { onComplete() }, successDuration);
  }

  return (
    <StyledScheduleDeliveryModal className="ScheduleDeliveryModal">
      {!showSuccessMessage ?
        <>
          <div className="ScheduleDeliveryModal_processSteps divider">
            <ProcessSteps steps={steps} current={currentStepIndex} />
          </div>
          
          {currentStep === ProcessOrderStep.PrintManifest &&
            <div className="ScheduleDeliveryModal_printManifest">
              <p className="ScheduleDeliveryModal_warning bold">
                {`A manifest is a document containing a list of orders for delivery.`}
                <br /><br />
                {`Once you select the ‘Print Manifest’ button, this document cannot be changed.`}
              </p>
              <Alert type={AlertType.Important}>
                {process === Process.SchedulePickup && <p>You must sign the document and give it to the driver collecting the orders along with all of the associated packages.</p> }
                {process === Process.OrganiseDropoff && <p>You must sign the document and give it to the Australia Post employee along with all of the associated packages.</p> }
              </Alert>
              <Button text="Print Manifest" type={ButtonType.Primary} small icon={ButtonIcon.Print} colour={ButtonColour.Green} onClick={handlePrintManifest} loading={isLoading} />
              {errorMessage &&
                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                  <p>{errorMessage}<br/><DefaultErrorMessage /></p>
                </Alert>
              }
              <Button text="Proceed to Next Step" type={ButtonType.Primary} onClick={next} loading={isLoading} disabled={isDisabled} tall/>
            </div>
          }

          {currentStep === ProcessOrderStep.SchedulePickup &&
            <div className="ScheduleDeliveryModal_schedulePickup">
              <img className="ScheduleDeliveryModal_courierLogo" src={australiaPostLogo} alt="Australia Post logo" title="Australia Post logo" />
              <Heading heading="Contact Australia Post to schedule a pickup:" />
              <p className="ScheduleDeliveryModal_courierPhone">
                <img src={phoneIcon} alt="Phone number" />
                <span>{courierLocation.phone}</span>
              </p>
              <Button text="Finalise Pickup" type={ButtonType.Primary} onClick={handleCompleteProcess} tall />
            </div>
          }
          
          {currentStep === ProcessOrderStep.DropoffLocation &&
            <div className="ScheduleDeliveryModal_dropoffLocation">
              <Heading heading="Are you familiar with the drop off location?" subheading="You can only drop off parcels at this Australia Post location." />
              {/* <img className="ScheduleDeliveryModal_storeMap" src={australiaPostMapSrc()} alt="Australia Post location" title="Australia Post location" /> */}
              <p className="ScheduleDeliveryModal_courierName bold">
                {courierLocation.name}
              </p>
              <p className="ScheduleDeliveryModal_courierAddress">
                <img src={pinIcon} alt="Address" />
                <span>{courierLocation.address}</span>
              </p>
              <Button text="Yes, I Am" type={ButtonType.Primary} onClick={handleCompleteProcess} tall />
            </div>
          }
          
          <Button text="Back" type={ButtonType.Secondary} onClick={back} disabled={!isDisabled && !errorMessage} />
        </>
      :
        <>
          <Alert type={AlertType.PositiveSecondary} successModal>
            {process === Process.SchedulePickup &&
              <>
                {dummyData.state.useDummyData ?
                  <p>These orders are scheduled for pickup today and can be found in <span className="extrabold">Dispatch & Collection &gt; Awaiting Courier Pickup</span></p>
                :
                  <p>These orders are scheduled for pickup today and can be found in <span className="extrabold">Closed Orders &gt; Completed Orders</span></p>
                }
              </>
            }
            {process === Process.OrganiseDropoff &&
              <>
                {dummyData.state.useDummyData ?
                  <p>These orders are ready for drop off at your local Australia Post and can be found in <span className="extrabold">Dispatch & Collection &gt; Awaiting Drop Off</span></p>
                :
                  <p>These orders are ready for drop off at your local Australia Post and can be found in <span className="extrabold">Closed Orders &gt; Completed Orders</span></p>
                }
              </>
            }
          </Alert>
        </>
      }

      {/* printable manifest */}
      <div className="printable_hidden" ref={printRef}>
        <Document file={currentManifest} options={options} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(
            new Array(numPages),
            (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ),
          )}
        </Document>
      </div>
    </StyledScheduleDeliveryModal>
  );
}

const StyledScheduleDeliveryModal = styled.div`
  .Heading {
    margin-top: 45px;
  }

  .SelectionGroup_list {
    margin-bottom: 48px;
    grid-gap: 14px;
    gap: 14px;

    div > label {
      font-family: 'Mulish Medium';
    }
  }

  .ScheduleDeliveryModal_courierLogo {
    width: 172px;
    height: auto;
    margin: 29px 0 20px 0;
    
    & ~ div > .Heading {
      margin-top: 0;
    }
  }

  .ScheduleDeliveryModal_courierName {
    margin: 29px 0 10px 0;
    font-size: 1.125rem; // 18px
  }
  
  .ScheduleDeliveryModal_courierAddress {
    display: flex;
    align-items: center;
    margin: 0 0 46px 0;

    img {
      width: 22px;
      height: auto;
      margin: 0 10px 0 0;
    }
  }

  .ScheduleDeliveryModal_courierPhone {
    display: flex;
    align-items: center;
    margin: 29px 0 46px 0;

    img {
      width: 22px;
      height: auto;
      margin: 0 10px 0 0;
    }
  }

  /* .Alert {
    margin: -19px 0 -33px 0;
    p {
      font-size: 1rem !important; // 16px
    }
  } */

  .Alert_urgent {
    margin-bottom: 25px;
    margin-top: -25px;
  }

  .ScheduleDeliveryModal_storeMap {
    width: 100%;
    height: auto;
    margin: 19px 0 43px 0;
  }
  
  .Button_primary {
    margin-bottom: 23px;
  }

  .ScheduleDeliveryModal_printManifest {
    .ScheduleDeliveryModal_warning {
      margin: 30px 0 25px 0;
    }

    .Button_Green {
      margin-top: 14px;
      margin-bottom: 52px;
    }
  }
  .ScheduleDeliveryModal_schedulePickup {

  }
  .ScheduleDeliveryModal_dropoffLocation {

  }
`