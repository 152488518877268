import { FunctionComponent, useState, ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { Cart, SparrowHubApiInterface, UpdateCartRequest } from 'sparrowhub-client-axios';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { TextArea } from '../TextArea/TextArea';
import { DefaultErrorMessage } from '../DefaultErrorMessage/DefaultErrorMessage';
import { CartBadges } from '../CartBadges/CartBadges';


type EditCartNoteModalProps = {
  show: boolean
  cart: Cart
  onClose: Function
  onRefresh: Function
}

export const EditCartNoteModal: FunctionComponent<EditCartNoteModalProps> = ({ show, cart, onClose, onRefresh }) => {
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();

  // state
  const [initialValue, setInitialValue] = useState('');
  const [noteInput, setNoteInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  // set initial values
  useEffect(() => {
    const noteString = !cart.note ? '' : cart.note;
    setInitialValue(noteString);
    setNoteInput(noteString);
  }, [ show ]);

  // methods
  const saveChanges = (): void => {
    setErrorMessage('');
    handleUpdateNote();
  }

  const handleUpdateNote = (): void => {
    setIsLoading(true);
    setErrorMessage('');
    const requestBody: UpdateCartRequest = {
      note: noteInput
    }

    api.updateCart(cart.id, requestBody)
      .then(response => {
        setIsLoading(false);

        if (response.data && response.data.status === 'success') {
          onRefresh();
          setTimeout(() => { onClose() }, 300);
        }
      })
      .catch(error => {
        setIsLoading(false);
        setErrorMessage(`Error updating cart note.`);
        console.log(error)
      })
  }

  const validChanges = (): boolean => {
    return noteInput.trim() !== initialValue.trim();
  }

  return (
    <StyledEditCartNoteModal className="OrderNotes">
      <Modal show={show}>
        <Heading heading="Order Notes" />
        <div className="OrderNotes_details divider">
          <CartBadges cart={cart} />
          <OrderDetails order={cart} phone={cart.delivery_phone !== null} email={cart.delivery_email !== null} cart bold={false} />
        </div>

        <TextArea id="notes-input" label={`${initialValue === '' ? 'Add' : 'Edit'} order note`} value={noteInput} maxLength={512} small onChange={(e: ChangeEvent) => setNoteInput((e.target as HTMLTextAreaElement).value)} />

        {errorMessage &&
          <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
            <p>{errorMessage}<br/><DefaultErrorMessage /></p>
          </Alert>
        }

        <Button type={ButtonType.Primary} text="Save Changes" onClick={saveChanges} disabled={!validChanges()} loading={isLoading} />
        <Button type={ButtonType.Secondary} text="Cancel" onClick={() => onClose()} />
      </Modal>
    </StyledEditCartNoteModal>
  );
}

const StyledEditCartNoteModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;

    /* .Badge {
      position: absolute;
      top: 60px;
      right: 50px;
    } */
    
    .OrderNotes_details {
      margin-top: 28px;
      margin-bottom: 38px;

      .Badge {
        display: inline-flex;
        margin-right: 8px;
      }
    }

    .OrderNotes_existing {
      textarea {
        margin-bottom: 0px;
      }

      .OrderNotes_note {
        padding: 8px;
        margin: 10px 0;
        border-radius: 4px;
        background: #DADADA;
        p {
          margin: 0;
          font-size: 0.875rem; // 14px
          word-wrap: break-word;
        }
      }
    }

    .Button_primary {
      margin-top: 34px;
      margin-bottom: 23px;
    }
  }
`