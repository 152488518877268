import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { IUser } from '../../types/IUsers';
import { NotificationType } from '../../types/INotifications';

import { QueueElement, Location, PolicyFeature, ProcessNotificationNewRequest, NotificationTypes } from "sparrowhub-client-axios";

import { useApi } from '../../context/ApiProvider';
import { SparrowHubApiInterface } from 'sparrowhub-client-axios';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { Badge } from '../Badge/Badge';
import { ScriptDetails } from '../ScriptDetails/ScriptDetails';
import { DefaultErrorMessage } from '../DefaultErrorMessage/DefaultErrorMessage';
import { NotificationPreviewInput } from '../NotificationPreviewInput/NotificationPreviewInput';


type ScriptQueueNotificationModalProps = {
  show: boolean
  user: IUser
  order: QueueElement
  onClose: Function
  location: Location
}

export const ScriptQueueNotificationModal: FunctionComponent<ScriptQueueNotificationModalProps> = ({ show, user, order, onClose, location }) => {
  const { api }: {api: SparrowHubApiInterface } = useApi();
  
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [phone, setPhone] = useState(order.customer_phone);

  // methods
  const handleSendNotification = async (): Promise<void> => {
    if (phone !== '') {
      // set state
      setErrorMessage('');
      setIsLoading(true);

      // generate reCAPTCHA token
      await grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action: 'contact'});
        sendSMSNotification(token);
      });
    }
  }

  const successDuration = 3000;
  const sendSMSNotification = async (recaptcha: string): Promise<any> => {
    setErrorMessage('');
    const requestBody: ProcessNotificationNewRequest = {
      notification_type_code: NotificationTypes.Sms,
      security_token: recaptcha,
      message: notificationMessage,
      partner: {
        id: user!.partner_id
      },
      contact: {
        first_name: order.customer_first_name || '',
        last_name: order.customer_last_name || '',
        email: '',
        phone: phone
      },
    }

    api.publicProcessNewNotification(requestBody)
      .then(response => {
        console.log('notification response')
        console.log(response)

        setIsLoading(false);
        setShowSuccess(true);
        setTimeout(() => {
          handleClose();
        }, successDuration);
      })
      .catch(error => {
        console.log('Error sending notification')
        console.log(error);
        setIsLoading(false);
        setErrorMessage(`Error sending notification: ${error.response.data.message}`);
      })
  }

  const transitionDuration = 300;
  const handleClose = (): void => {
    setTimeout(() => {
      setShowSuccess(false);
    }, transitionDuration);

    if (onClose) onClose();
  }

  return (
    <StyledScriptQueueNotificationModal className="ScriptQueueNotificationModal">
      <Modal show={show}>
        {!showSuccess &&
          <>
            <Heading heading={`Send a message to your customer`} />
            <div className="ScriptQueueNotificationModal_details">
              <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                <Badge type="scriptQueue_inStore" />
                {order.requires_consultation &&
                  <Badge type="scriptQueue_requiresCounselling" />
                }
              </div>
              <ScriptDetails order={order} />
            </div>

            <NotificationPreviewInput
              feature={PolicyFeature.Queue}
              location={location}
              firstname={order.customer_first_name || ''}
              notificationType={NotificationType.Sms}
              phone={phone || ''}
              message={notificationMessage}
              onSetPhone={setPhone}
              onSetMessage={setNotificationMessage}
            />

            {errorMessage &&
              <div style={{ marginTop: '30px', marginBottom: '-20px' }}>
                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                  <p>{errorMessage}<br/><DefaultErrorMessage /></p>
                </Alert>
              </div>
            }
            <Button type={ButtonType.Primary} tall text="Send SMS" onClick={handleSendNotification} loading={isLoading} disabled={phone === '' || notificationMessage === ''} />
            <Button type={ButtonType.Secondary} text="Cancel" onClick={handleClose} />
          </>
        }
        {showSuccess &&
          <Alert type={AlertType.PositiveSecondary} successModal>
            <p>This SMS message was sent successfully.</p>
          </Alert>
        }
      </Modal>
    </StyledScriptQueueNotificationModal>
  );
}

const StyledScriptQueueNotificationModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;

    .ScriptQueueNotificationModal_details {
      margin-top: 28px;
    }

    .ScriptQueueNotificationModal_grid {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      grid-gap: 25px 14px;
      gap: 25px 14px;
      margin: 50px 0;

      .Button {
        width: 100%;
        height: 128px;
        margin: 0 !important;
      }
    }
  }

  .Button_primary {
    margin-top: 50px;
    margin-bottom: 23px;
  }
`