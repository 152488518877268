import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { transitions } from '../../assets/css/variables';


type ToggleProps = {
  label?: string
  options: Array<string>
  state: boolean
  fullWidth?: boolean
  onChange?: Function
}

export const Toggle: FunctionComponent<ToggleProps> = ({ label, options, state, fullWidth, onChange }) => {
  // computed
  const sliderStyle = (): any => {
    if (fullWidth) {
      return {
        transform: `translateX(${state ? '98%' : '0'})`
      }
    } else {
      return {
        // transform: `translateX(${state ? '60px' : '0'})`
        transform: `translateX(${state ? '80px' : '0'})`
      }
    }
  }
  
  // methods
  const handleChange = (): void => {
    if (onChange) onChange();
  }
  
  return (
    <StyledToggle className={`Toggle ${fullWidth && 'fullWidth'}`}>
      {label &&
        <label className="Toggle_label medium">{label}</label>
      }
      <div className="Toggle_outer" onClick={handleChange}>
        <div className="Toggle_slider" style={sliderStyle()}></div>
        <p className="Toggle_option">{options[0]}</p>
        <p className="Toggle_option">{options[1]}</p>
      </div>
    </StyledToggle>
  );
}

const StyledToggle = styled.div`
  display: flex;
  align-items: center;
  margin: 2px 0;

  .Toggle_label {
    font-size: 0.625rem; // 10px
    margin-right: 7px;
  }

  .Toggle_outer {
    position: relative;
    display: flex;
    /* width: 124px; */
    width: 164px;
    height: 24px;
    border-radius: 4px;
    background: rgba(235, 235, 235, 0.5);
    cursor: pointer;

    .Toggle_slider {
      position: absolute;
      top: 2px;
      left: 2px;
      /* width: 60px; */
      width: 80px;
      height: 20px;
      border-radius: 4px;
      background: white;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
      transition: transform ${transitions.default};
    }

    .Toggle_option {
      z-index: 1;
      /* width: 60px; */
      width: 80px;
      height: 20px;
      font-size: 0.625rem; // 10px
      margin: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
    }
  }

  /* FULL WIDTH STYLE */
  &.fullWidth {
    .Toggle_outer {
      width: 100%;
    }

    .Toggle_slider, .Toggle_option {
      width: 50%;
    }
  }
`