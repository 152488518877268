import { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { transitions } from '../../assets/css/variables';


type SliderProps = {
  label: string | ReactNode
  labelFalse?: string | ReactNode
  state: boolean
  reverse?: boolean
  smallLabel?: boolean
  bold?: boolean
  onChange?: Function
}

export const Slider: FunctionComponent<SliderProps> = ({ label, labelFalse, state, reverse, smallLabel, bold, onChange }) => {
  // computed
  const sliderStyle = (): any => {
    return {
      transform: `translateX(${state ? '20px' : '0'})`
    }
  }
  
  // methods
  const handleChange = (): void => {
    if (onChange) onChange();
  }
  
  return (
    <StyledSlider className={`Slider ${reverse && 'reverse'}`} onClick={handleChange}>
      <div className={`Slider_outer ${state ? 'active' : 'inactive'}`}>
        <div className="Slider_slider" style={sliderStyle()}></div>
      </div>
      <label className={`Slider_label ${bold && 'bold'} ${smallLabel && 'smallLabel'}`}>{state ? label : (labelFalse || label)}</label>
    </StyledSlider>
  );
}

Slider.defaultProps = {
  reverse: false,
  smallLabel: false,
  bold: true
}

const StyledSlider = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 2px 0;
  cursor: pointer;

  &.reverse {
    flex-direction: row-reverse;
  }

  .Slider_label {
    font-family: "Mulish SemiBold";
    font-size: 0.75rem; // 12px
    cursor: pointer;
    user-select: none;

    &.smallLabel {
      font-size: 0.625rem; // 10px
    }
  }

  .Slider_outer {
    position: relative;
    display: flex;
    width: 46px;
    height: 24px;
    border-radius: 35px;
    transition: background-color ${transitions.default};

    &.active {
      background-color: #69CEF6;
    }

    &.inactive {
      background-color: #EBEBEB;
    }

    .Slider_slider {
      position: absolute;
      top: 3px;
      left: 4px;
      width: 18px;
      height: 18px;
      border-radius: 100%;
      background: white;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
      transition: transform ${transitions.default};
    }
  }
`