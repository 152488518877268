import { FunctionComponent, useState } from 'react';
import { NavLink, To, useLocation, Location, Navigate, useNavigate } from 'react-router-dom';
import { colours } from '../../assets/css/variables';
import styled from 'styled-components';

import { l18n } from '../../common/l18n';
import { useDummyData } from '../../context/DummyDataProvider';

import { IUser, UserRoleCode } from "../../types/IUsers";
import { ILocation } from '../../types/ILocations';
import { Product } from '../../types/Products';
import { GetCurrentPartnerResponse, PolicyFeature, RedactedLocation } from 'sparrowhub-client-axios';

import { Menu } from '../Menu/Menu';
import { ProductMenu } from '../ProductMenu/ProductMenu';
import { Button, ButtonIcon, ButtonType } from '../Button/Button';

import productMenuIcon from '../../assets/images/icons/ProductMenu.svg';
import menuIcon from '../../assets/images/icons/HamburgerMenu.svg';
import sparrowhubLogo from '../../assets/images/logos/sparrowhub-lockup.svg';


// header links
const pageLinks = [
  { title: 'Orders', url: '/send/orders' },
  // { title: 'Account', url: '/u/1' },
  // { title: 'Switch User', url: '#' },
  // { title: 'Reset password (create)', url: '/reset-password' },
  // { title: 'Reset password (confirm)', url: '/reset-password/confirm' },
];

const showDebugNav = false;

type ProductDetails = {
  product: Product
  alt: string
  logo: string
  to: To
}

type HeaderProps = {
  loggedin: boolean
  user: IUser | null
  partner?: GetCurrentPartnerResponse | null
  location: ILocation | null
  type: string
  onSelectLocation?: Function
  onSelectUser?: Function
  onLogout?: Function
}

export const Header: FunctionComponent<HeaderProps> = ({ loggedin, user, partner, location, type, onSelectLocation, onSelectUser, onLogout }) => {
  const domLocation: Location = useLocation();
  const navigate = useNavigate();

  // state
  const [showProductMenu, setShowProductMenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const dummyData: any = useDummyData();

  // computed
  const currentProductDetails = (): ProductDetails => {
    if (domLocation.pathname.includes('/send')) {
      return {
        product: Product.SparrowSend,
        alt: 'SparrowHub',
        logo: sparrowhubLogo,
        to: '/send/orders'
      }
    } else if (domLocation.pathname.includes('/phone')) {
      return {
        product: Product.SparrowPhone,
        alt: 'SparrowHub',
        logo: sparrowhubLogo,
        to: '/phone/process/create-order'
      }
    } else if (domLocation.pathname.includes('/insight')) {
      return {
        product: Product.SparrowInsight,
        alt: 'SparrowHub',
        logo: sparrowhubLogo,
        to: '/insight'
      }
    } else if (domLocation.pathname.includes('/consult')) {
      return {
        product: Product.SparrowConsult,
        alt: 'SparrowHub',
        logo: sparrowhubLogo,
        to: '/consult'
      }
    } else if (domLocation.pathname.includes('/queue')) {
      return {
        product: Product.SparrowQueue,
        alt: 'SparrowHub',
        logo: sparrowhubLogo,
        to: '/queue/orders'
      }
    } else {
      return {
        product: Product.SparrowHub,
        alt: 'SparrowHub',
        logo: sparrowhubLogo,
        to: '/'
      }
    }
  }

  return (
    <StyledHeader>
      <header className={type}>
        {loggedin && type === 'default' &&
          <img src={productMenuIcon} className="Header_productMenuIcon button" alt="Product menu" onClick={() => setShowProductMenu(true)} draggable="false" />
        }

        {/* main logo/home link */}
        <NavLink className="Header_home" to={currentProductDetails().to} title="Home">
          <img src={currentProductDetails().logo} alt={currentProductDetails().alt} title={currentProductDetails().alt} />
        </NavLink>

        {(
          loggedin && type === 'default' &&
          currentProductDetails().product === Product.SparrowSend &&
          (partner !== null && partner?.policies.features.includes(PolicyFeature.Carts)) &&
          (location !== null && (location as unknown as RedactedLocation)?.policies.features.includes(PolicyFeature.Carts))
        ) &&
          <div className="Header_newOrder">
            <Button type={ButtonType.Primary} icon={ButtonIcon.AddCircle} text="New Order" onClick={() => navigate('/send/process/create-order')} />
          </div>
        }

        {/* nav content */}
        {loggedin && type === 'default' &&
          <>
            {dummyData.state.useDummyData &&
              <p className="Header_userInfo">
                You are logged in as Hamish<br />
                {location?.name}
              </p>
            }
            <img src={menuIcon} className="Header_hamburgerIcon button" alt="Nav menu" onClick={() => setShowMenu(true)} draggable="false" />

            <ProductMenu visible={showProductMenu} userRole={user?.role_code} onClose={() => setShowProductMenu(false)} />
            <Menu visible={showMenu} userRole={user?.role_code} location={location} onClose={() => setShowMenu(false)} onSelectLocation={onSelectLocation!} onSelectUser={onSelectUser!} onLogout={onLogout!} />
          </>
        }
      </header>

      {showDebugNav &&
        <DebugNav>
          <p>Debug Nav</p>
          <hr />

          {/* home */}
          <NavLink to="/" title={l18n.label.website_name}><span>{l18n.label.website_name}</span></NavLink>
          <hr />

          {/* page links */}
          <nav>
            {pageLinks.map((link) => (
              <NavLink to={link.url} title={link.title} key={link.title}><span>{link.title}</span></NavLink>
            ))}
          </nav>
          <hr />

          {/* log in/out */}
          <div>
            {!loggedin ?
              <NavLink to="/login" title={l18n.label.login}><span>{l18n.label.login}</span></NavLink>
              :
              <NavLink to="/logout" title={l18n.label.logout}><span>{l18n.label.logout}</span></NavLink>
            }
          </div>
        </DebugNav>
      }
    </StyledHeader>
  );
}

Header.defaultProps = {
  onSelectLocation: () => { return undefined },
  onSelectUser: () => { return undefined },
}

const StyledHeader = styled.div`
  /* height: 79px; */
  height: 54px;
  margin: 0 0 45px 0;

  header {
    z-index: 998;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    min-height: 54px;

    .Header_logout {
      padding: 8px;
      margin-right: -8px;
    }

    /* large centered header for login screens */
    &.login {
      height: 74px;
      justify-content: center;
      margin: 40px auto 20px auto;

      .Header_home {
        img {
          height: 40px;
          width: auto;
        }
      }
    }

    /* default left-aligned header for main screens */
    &.default {
      position: fixed;
      margin: 0 0 45px 0;
      padding: 0 23px;
      background: white;
      align-items: center;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);

      .Header_home {
        cursor: pointer;
        margin-top: 5px;
        margin-right: auto;

        img {
          height: 20px;
          width: auto;
        }
      }

      .Header_productMenuIcon {
        margin-right: 11px;
      }
      
      .Header_hamburgerIcon {
        width: 35px;
        height: 35px;
      }

      .Header_userInfo {
        font-family: 'Mulish Bold';
        font-size: 0.75rem; // 12px
        text-align: right;
        line-height: 125%;
        margin-right: 19px;
      }

      .Header_newOrder {
        margin-right: 23px;

        .Button {
          height: 30px;
          font-size: 0.75rem; // 12px
          padding: 12px;

          img {
            margin-left: 10px;
          }
        }
      }
    }
  }
`

const DebugNav = styled.nav`
  position: fixed;
  z-index: 99;
  top: 10px;
  left: 10px;
  width: fit-content;
  max-width: 150px;
  padding: 10px;
  background-color: white;
  border: 1px solid ${colours.lightGrey};
  border-radius: 6px;

  a {
    display: block;
    font-size: 0.875rem; // 14px
  }

  p {
    margin: 0;
  }

  hr {
    border-top: 1px solid ${colours.lightGrey};
  }
`