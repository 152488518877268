import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { IOrder } from '../../types/IOrders';
import { IOrderNote } from '../../types/IOrderNotes';
import { formatTimestamp, objectIsCart } from '../../helpers/utils';

import notesIcon from '../../assets/images/icons/Notes.svg';
import editIcon from '../../assets/images/icons/Edit.svg';
import { QueueElement } from 'sparrowhub-client-axios';


type OrderNotesProps = {
  order: IOrder | QueueElement
  onEditNote?: Function
  noDate?: boolean
  numNotes?: number
}

export const OrderNotes: FunctionComponent<OrderNotesProps> = ({ order, onEditNote, noDate, numNotes }) => {
  // computed
  const notes = (): Array<IOrderNote> => {
    if (Object.hasOwn(order, 'queue_note') || objectIsCart(order)) {
      return [];
    } else {
      return numNotes !== undefined
        ? (order as IOrder).notes.slice(0, numNotes)
        : (order as IOrder).notes;
    }
  }

  return (
    <StyledOrderNotes className="OrderNotes">
      <img className="OrderNotes_notesIcon" src={notesIcon} alt="" />
      <div className="OrderNotes_noteContainer">
        {objectIsCart(order) || Object.hasOwn(order, 'queue_note') ?
          <div key={`${objectIsCart(order) ? 'cart' : 'queue'}-${order.id}-note`}>
            <p className="ellipsisMultiline">
              {(order as any).note || (order as QueueElement).queue_note}
            </p>
            {onEditNote &&
              <img className="button" src={editIcon} alt="Edit order notes" onClick={() => onEditNote()} draggable="false" />
            }
          </div>
        :
          <>
            {notes().map((note: IOrderNote) => {
              return (
                <div key={`order-${order.id}-note-${note.id}`}>
                  <p className="ellipsisMultiline">
                    {!noDate &&
                      <>{formatTimestamp(note.created_at)} - </>
                    }
                    {note.note}
                  </p>
                  {onEditNote &&
                    <img className="button" src={editIcon} alt="Edit order notes" onClick={() => onEditNote(note.id)} draggable="false" />
                  }
                </div>
              )
            })}
          </>
        }
      </div>
    </StyledOrderNotes>
  );
}

const StyledOrderNotes = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 15px;

  .OrderNotes_notesIcon {
    width: 17px;
    margin-left: 3px;
    margin-right: 13px;
  }

  .OrderNotes_noteContainer {
    width: 100%;
    div {
      display: flex;
      align-items: flex-start;
      position: relative;
      padding-right: 40px;
      max-width: 441px;

      p {
        height: fit-content;
        max-height: 40px;
        flex-grow: 1;
        margin: 0;
        font-size: 0.75rem; // 12px
        line-height: 164%;
        -webkit-line-clamp: 2;
        word-wrap: break-word;
      }

      img {
        margin-left: 17px;
        cursor: pointer;

        padding: 8px;
        margin-right: -8px;
        margin-top: -8px;

        position: absolute;
        right: 0;
      }
    }
  }
`