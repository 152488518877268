
import { IntegrationTypes } from "sparrowhub-client-axios"
import { LocationCode } from "./ILocations"
import { IOrderItems } from "./IOrderItems"
import { IOrderNotes } from "./IOrderNotes"
import { Timestamp } from "./Timestamp"

export enum OrderType {
  Sale = "sale",
  Appointment = "appointment"
}

export enum OrderDeliveryType {
  Standard = "standard",
  Pickup = "pickup",
  Express = "express",
  SameDay = "same_day",
  Free = "free",
  Unknown = "unknown",
  Custom = "custom",
  InStore = "in_store",
  LochtingLocker = "locker",
  LochtingLocal = "localDelivery",
  LochtingService = "serviceDelivery",
  ExternalPickup = "external_pickup"
}

export enum CourierType {
  Custom = "custom-courier",
  AustraliaPost = "auspost",
  Doordash = "doordash",
  Aramex = "aramex",
}

export enum DeliveryModelCode {
  Pickup = "shipment-collection",
  Dropoff = "shipment-dropoff",
  Return = "return",
  Self = "self-delivery",
}

export enum ParcelType {
  Custom = "box-custom",
  BoxCubeLarge = "box_cube_large",
  BoxCubeSmall = "box_cube_small",
  BoxMedium = "box_medium",
  SatchelA3New = "satchel_a3_new",
  SatchelA4New = "satchel_a4_new",
  SatchelA5New = "satchel_a5_new",
  // deprecated
  BoxCube = "box-cube",
  BoxA4 = "box-a4",
  SatchelA5 = "satchel-a5",
  SatchelA4 = "satchel-a4",
  SatchelA3 = "satchel-a3"
}

export enum OrderStatus {
  New = "new",
  OnHold = "on_hold",
  Fraud = "fraud",
  Cancelled = "cancelled",
  AwaitingReview = "awaiting_review",
  AwaitingPayment = "awaiting_payment",
  AwaitingPickup = "awaiting_pickup",
  AwaitingProcessing = "awaiting_processing",
  AwaitingDropoff = "awaiting_dropoff",
  AwaitingAppointment = "awaiting_appointment",
  AwaitingCourier = "awaiting_courier",
  InTransit = "in_transit",
  Complete = "complete",
  Refunded = "refunded",
  Archived = "archived",
}

export enum OrderState {
  New = "new",
  Pending = "pending",
  Processing = "processing",
  Complete = "complete",
}

export enum OrderUrgency {
  Standard = "standard",
  Warning = "warning",
  Overdue = "overdue",
}

export type PlatformPaymentMethod = string

export type PlatformDeliveryMethod = "tablerate_bestway" | "instore_pickup" | "flateratefive_flaterate"

export enum AddressCountry {
  AU = "AU",
}

export enum AddressState {
  NSW = "NSW",
  QLD = "QLD",
  VIC = "VIC",
  SA = "SA",
  WA = "WA",
  NT = "NT",
  TAS = "TAS",
  ACT = "ACT",
}


export interface IOrder {
  id: number
  is_visible: boolean
  order_type_code: OrderType
  created_at: Timestamp
  ordered_at: Timestamp
  updated_at: Timestamp
  completed_at: Timestamp
  location_code: LocationCode
  delivery_type_code: OrderDeliveryType
  status_code: OrderStatus
  order_state_code: OrderState
  platform_customer_id: number
  platform_order_id: number
  platform_order_no: string
  platform_payment_method: PlatformPaymentMethod
  platform_delivery_method: PlatformDeliveryMethod
  customer_ip: string | null
  customer_email: string
  customer_firstname: string
  customer_lastname: string
  total: string
  total_paid: string
  total_owing: string
  total_weight: string
  tax: string
  discount: string
  discount_tax_offset: string
  delivery: string
  delivery_tax: string
  delivery_discount: string
  delivery_discount_tax_offset: string
  billing_firstname: string
  billing_lastname: string
  billing_email: string
  billing_phone: string
  billing_street: string
  billing_city: string
  billing_state_code: AddressState
  billing_postcode: string
  billing_country_code: AddressCountry
  delivery_firstname: string
  delivery_lastname: string
  delivery_email: string
  delivery_phone: string
  delivery_street: string
  delivery_city: string
  delivery_state_code: AddressState
  delivery_postcode: string
  delivery_country_code: AddressCountry
  delivery_model_code: DeliveryModelCode | null
  integration_id: number
  partner_id: number
  items: IOrderItems
  notes: IOrderNotes
  refund_adjustment: string
  refund_delivery: string
  courier_type_code?: CourierType
  order_number?: string
  integration_type_code?: IntegrationTypes
  initial_reservation_id?: string
  initial_reservation_status?: string
  final_reservation_id?: string
  final_reservation_status?: string
  cart_id?: number
}

export interface IOrders extends Array<IOrder> { };
