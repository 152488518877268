import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { IUser } from '../../types/IUsers';
import { IScriptQueueConfig } from '../../types/IScriptQueueConfig';

import { QueueElement } from "sparrowhub-client-axios";

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { SparrowHubApiInterface } from 'sparrowhub-client-axios';
import { useDummyData } from '../../context/DummyDataProvider';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { Badge } from '../Badge/Badge';
import { ScriptDetails } from '../ScriptDetails/ScriptDetails';
import { DefaultErrorMessage } from '../DefaultErrorMessage/DefaultErrorMessage';


type ScriptQueueConfirmationModalProps = {
  show: boolean
  order: QueueElement
  setDummyStatus?: Function
  onClose?: Function
  orders: any
  setOrders: Function
  user: IUser | null
  config: IScriptQueueConfig
  onComplete: Function
}

export const ScriptQueueConfirmationModal: FunctionComponent<ScriptQueueConfirmationModalProps> = ({ show, order, setDummyStatus, onClose, orders, setOrders, user, config, onComplete }) => {
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();
  const dummyData = useDummyData();
  
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // methods
  const successDuration = 3000;
  const handleCompleteProcess = (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      setErrorMessage('');
      setIsLoading(true);
      if (dummyData.state.useDummyData) {
        // onRefresh();
        if (setDummyStatus) {
          setDummyStatus('awaiting_pickup').then(() => {
            setShowSuccess(true);
            setIsLoading(false);

            setTimeout(() => {
              handleClose();
            }, successDuration);
          })
        };
        resolve('');
      } else {
        setIsLoading(false);
        onComplete();
        handleClose();
      }
    });
  }

  const transitionDuration = 300;
  const handleClose = (): void => {
    setTimeout(() => {
      setShowSuccess(false);
    }, transitionDuration);

    if (onClose) onClose();
  }

  return (
    <StyledScriptQueueConfirmationModal className="ScriptQueueConfirmationModal">
      <Modal show={show}>
        {!showSuccess &&
          <>
            <Heading heading={`This will alert the customer that their scripts are ready for collection.`} />
            <div className="ScriptQueueConfirmationModal_details">
              <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                <Badge type="scriptQueue_inStore" />
                {order.requires_consultation &&
                  <Badge type="scriptQueue_requiresCounselling" />
                }
              </div>
              <ScriptDetails order={order} />
            </div>
            {errorMessage &&
              <div style={{ marginTop: '30px', marginBottom: '-20px' }}>
                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                  <p>{errorMessage}<br/><DefaultErrorMessage /></p>
                </Alert>
              </div>
            }
            <Button type={ButtonType.Primary} tall text="Mark as Ready for Customer Collection" onClick={handleCompleteProcess} loading={isLoading} />
            <Button type={ButtonType.Secondary} text="Cancel" onClick={handleClose} />
          </>
        }
        {showSuccess &&
          <Alert type={AlertType.PositiveSecondary} successModal>
            <p>This script has been marked as ready for collection and the customer has been notified.</p>
          </Alert>
        }
      </Modal>
    </StyledScriptQueueConfirmationModal>
  );
}

const StyledScriptQueueConfirmationModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;

    .ScriptQueueConfirmationModal_details {
      margin-top: 28px;
    }

    .ScriptQueueConfirmationModal_grid {
      display: grid;
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      grid-gap: 25px 14px;
      gap: 25px 14px;
      margin: 50px 0;

      .Button {
        width: 100%;
        height: 128px;
        margin: 0 !important;
      }
    }
  }

  .Button_primary {
    margin-top: 50px;
    margin-bottom: 23px;
  }
`