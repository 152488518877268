import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { useApi } from '../../context/ApiProvider';
import { Cart, SparrowHubApiInterface, UpdateCartRequest } from 'sparrowhub-client-axios';
import { formatCartLink } from '../../helpers/utils';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { CopyText } from '../CopyText/CopyText';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { DefaultErrorMessage } from '../DefaultErrorMessage/DefaultErrorMessage';
import { CartBadges } from '../CartBadges/CartBadges';
import { OrderDetails } from '../OrderDetails/OrderDetails';

import closeIcon from '../../assets/images/icons/Close.svg';


type DisableCartModalProps = {
  show: boolean
  cart: Cart
  onClose: Function
  onRefresh: Function
}

export const DisableCartModal: FunctionComponent<DisableCartModalProps> = ({ show, cart, onClose, onRefresh }) => {
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();
  
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // methods
  const handleDisableCart = (): void => {
    setIsLoading(true);
    setErrorMessage('');
    const requestBody: UpdateCartRequest = {
      is_enabled: false
    }

    api.updateCart(cart.id, requestBody)
      .then(response => {
        setIsLoading(false);

        if (response.data && response.data.status === 'success') {
          const successDuration = 3000;
          setShowSuccessMessage(true);
          onRefresh();
          setTimeout(() => { onClose() }, successDuration);
        }
      })
      .catch(error => {
        setIsLoading(false);
        setErrorMessage('Unable to cancel cart.')
        console.log(error)
      })
  }

  return (
    <StyledDisableCartModal className="DisableCartModal">
      <Modal show={show}>
        {!showSuccessMessage ?
          <>
            <Heading heading="Are you sure you want to cancel this cart?" />
            <div className="OrderNotes_details divider">
              <CartBadges cart={cart} />
              <OrderDetails order={cart} phone={cart.delivery_phone !== null} email={cart.delivery_email !== null} cart bold={false} />
            </div>
            
            <p className="bold">The customer will no longer be able to access this cart if they navigate to this link: {formatCartLink(cart.reference)}</p>
            <CopyText text={formatCartLink(cart.reference)} />
            <p className="bold" style={{ marginTop: '30px' }}>This action cannot be undone and a new cart will need to be created if required.</p>

            {errorMessage &&
              <div style={{ marginTop: '20px', marginBottom: '-20px' }}>
                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                  <p>{errorMessage}<br/><DefaultErrorMessage /></p>
                </Alert>
              </div>
            }

            <Button type={ButtonType.Primary} text="Yes, I'm Sure" onClick={handleDisableCart} loading={isLoading} />
            <Button type={ButtonType.Secondary} text="Cancel" onClick={() => onClose()} />

            <img src={closeIcon} alt="Close" className="Close button top_right" onClick={() => onClose()} draggable="false" />
          </>
        :
          <>
            <Alert type={AlertType.PositiveSecondary} successModal>
              <p>This cart has been successfully cancelled and can be found in <span className="extrabold">Closed Orders &gt; Completed Orders</span></p>
            </Alert>
          </>
        }
      </Modal>
    </StyledDisableCartModal>
  );
}

const StyledDisableCartModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;

    .DisableCartModal_logo {
      height: 47px;
      width: auto;
      margin-bottom: 20px;
    }

    .Heading {
      margin-bottom: 26px;
    }

    form {
      margin-top: 40px;

      .divider {
        padding-bottom: 10px;
        margin-bottom: 34px;
      }

      .InputField {
        margin: 24px 0;
      }

      select, input {
        font-size: 0.875rem !important; // 14px
      }

      .DisableCartModal_columns {
        display: grid;
        gap: 10px;
        grid-gap: 10px;
        grid-template-columns: 275px 1fr;
        margin-bottom: -15px;

        & > div {
          margin: auto 0 24px 0;

          input {
            margin-bottom: 0;
          }
        }
      }
    }

    p.bold {
      margin-bottom: 30px;
    }

    .Button_primary {
      margin-top: 50px;
      margin-bottom: 23px;
    }

    .top_right {
      top: 35px;
      right: 35px;
    }
  }
`