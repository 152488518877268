import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { Cart } from 'sparrowhub-client-axios';

import { Badge } from '../Badge/Badge';
import { objectIsOpenCart } from '../../helpers/utils';


type CartBadgesProps = {
  cart: Cart
}

export const CartBadges: FunctionComponent<CartBadgesProps> = ({ cart }) => {
  return (
    <StyledCartBadges className="CartBadges">
      <Badge type={'cart_link'} iconOnly />
      {objectIsOpenCart(cart) && <Badge type={'cart_pending_payment'} />}
      {!cart.requires_delivery && <Badge type={'cart_payment_only'} />}
    </StyledCartBadges>
  );
}

const StyledCartBadges = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`