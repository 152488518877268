import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { transitions } from '../../assets/css/variables';

import { InputField } from '../InputField/InputField';
import { SelectionButton } from '../SelectionButton/SelectionButton';
import { SelectionGroupInputType } from '../SelectionGroup/SelectionGroup';

import deleteIcon from '../../assets/images/icons/TrashNew.svg';

type ProductInputProps = {
  index: number
  item: any
  onUpdate: Function
  onDelete?: Function
}

export const ProductInput: FunctionComponent<ProductInputProps> = ({ index, item, onUpdate, onDelete }) => {
  // methods
  const handleDeleteItem = (): void => {
    if (onDelete) onDelete();
  }
  
  const handleUpdateItem = (payload: any): void => {
    if (onUpdate) onUpdate({
      name: Object.hasOwn(payload, 'name') ? payload.name : item.name,
      sku: Object.hasOwn(payload, 'sku') ? payload.sku : item.sku,
      gtin: Object.hasOwn(payload, 'gtin') ? payload.gtin : item.gtin,
      price: Object.hasOwn(payload, 'price') ? payload.price : item.price,
      qty_ordered: Object.hasOwn(payload, 'qty_ordered') ? payload.qty_ordered : item.qty_ordered,
      gst: Object.hasOwn(payload, 'gst') ? payload.gst : item.gst
    })
  }
  
  return (
    <StyledProductInput className="ProductInput">
      <div className="ProductInput_header">
        <p className="ProductInput_index">Item {index + 1}</p>
        <div className="ProductInput_delete" onClick={() => handleDeleteItem()}>
          <img className="delete-button button" src={deleteIcon} alt="Delete item" draggable="false" />
          <p className="bold">Remove Item</p>
        </div>
      </div>
      <div className="ProductInput_fields">
        <InputField type="text" id={`barcode-${index}`} label="Barcode" value={item.gtin} maxLength={255} regex={/^\d+$/} required onChange={(e: ChangeEvent) => handleUpdateItem({ gtin: (e.target as HTMLInputElement).value })} autofocus />
        <InputField type="text" id={`productName-${index}`} label="Item Name" value={item.name} maxLength={255} required onChange={(e: ChangeEvent) => handleUpdateItem({ name: (e.target as HTMLInputElement).value })} />
        <InputField type="number" id={`qty-${index}`} label="Qty" min={0} step="any" regex={/^\d+$/} value={item.qty_ordered} required onChange={(e: ChangeEvent) => handleUpdateItem({ qty_ordered: (e.target as HTMLInputElement).value })} />
        {/* <InputField type="text" id={`sku-${index}`} label="SKU" value={item.sku} maxLength={128} required onChange={(e: ChangeEvent) => handleUpdateItem({ sku: (e.target as HTMLInputElement).value })} />  */}
        <InputField type="number" id={`price-${index}`} label="Unit Price" placeholder="$" min={0} step="any" regex={/^[0-9]*\.?[0-9]{0,2}$/} value={item.price} required onChange={(e: ChangeEvent) => handleUpdateItem({ price: (e.target as HTMLInputElement).value })} />
        <SelectionButton type={SelectionGroupInputType.Checkbox} checkbox id="gst" label="GST Free Item" selected={!item.gst} value="" onChange={(e: ChangeEvent) => handleUpdateItem({ gst: !item.gst })} />
      </div>
    </StyledProductInput>
  );
}

const StyledProductInput = styled.div`
  .ProductInput_header {
    border-bottom: 1px solid #DADADA;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    /* border: 1px solid green; */

    .ProductInput_index {
      font-size: 1rem; // 16px
      font-family: "Mulish Bold";
      margin: 0 0 10px 0;
    }

    .ProductInput_delete {
      position: relative;
      cursor: pointer;
      /* border: 1px solid red; */
      padding: 10px 0 6px 8px;
      margin-top: -10px;

      img.delete-button {
        transition: margin ${transitions.default};
      }

      p {
        position: absolute;
        top: 13px;
        right: 0;
        font-size: 0.75rem; // 12px
        text-transform: uppercase;
        color: #FE2323;
        width: 100px;
        text-align: right;
        margin: 0;
        opacity: 0;
        transition: opacity ${transitions.default};
      }

      &:hover {
        img.delete-button {
          margin-right: 87px;
        }

        p {
          opacity: 1;
        }
      }
    }
  }

  .ProductInput_fields {
    display: grid;
    gap: 20px 10px;
    grid-template-columns: 1fr 3fr 3fr 1fr;
    grid-template-rows: 79px 79px;

    /* barcode */
    .InputField:nth-child(1) {
      grid-row: 1;
      grid-column: 1 / 3;
    }

    /* item name */
    .InputField:nth-child(2) {
      grid-row: 1;
      grid-column: 3 / -1;
    }

    /* qty */
    .InputField:nth-child(3) {
      grid-row: 2;
      grid-column: 1;
    }
    
    /* price */
    .InputField:nth-child(4) {
      grid-row: 2;
      grid-column: 2;
    }

    /* gst */
    .SelectionButton:nth-child(5) {
      grid-row: 2;
      grid-column: 3;

      align-self: flex-end;
      margin: 0 0 -10px -14px;

      input {
        margin-right: 10px !important;
      }
    }
  }
`